import {
  PAY_BY_ME_VPOS_PAYMENT_FAIL,
  PAY_BY_ME_VPOS_PAYMENT_RESET,
  PAY_BY_ME_VPOS_PAYMENT_START,
  PAY_BY_ME_VPOS_PAYMENT_SUCCESS
} from "../constants/payByMe";
import {PayByMeActions, PayByMeState} from "../types/payByMe";

const initialState: PayByMeState = {
  vposPayment: {
    loading: false,
    data: undefined,
    error: undefined,
  }
}

const reducer = (
  state = initialState,
  action: PayByMeActions,
): PayByMeState => {
  switch (action.type) {
    case PAY_BY_ME_VPOS_PAYMENT_START:
      return {
        ...state,
        vposPayment: {
          ...initialState.vposPayment,
          loading: true,
        }
      }
    case PAY_BY_ME_VPOS_PAYMENT_SUCCESS:
      return {
        ...state,
        vposPayment: {
          ...state.vposPayment,
          loading: false,
          data: action.payload,
        }
      }
    case PAY_BY_ME_VPOS_PAYMENT_FAIL:
      return {
        ...state,
        vposPayment: {
          ...state.vposPayment,
          loading: false,
          error: action.error,
        }
      }
    case PAY_BY_ME_VPOS_PAYMENT_RESET:
      return {
        ...state,
        vposPayment: {
          ...initialState.vposPayment,
        }
      }
    default:
      return state;
  }
}

export default reducer;
