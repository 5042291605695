import { CombinedState, combineReducers, Reducer } from "redux";
import { AppState } from "../types/app";
import { CampaignState } from "../types/campaign";
import { ContactUsState } from "../types/contactUs";
import { EmailConfirmState } from "../types/emailConfirm";
import { IdentityInfoState } from "../types/identityInfo";
import { JobState } from "../types/job";
import { LoginMsisdnState } from "../types/loginMsisdn";
import { LoginOtpState } from "../types/loginOtp";
import { LoginSubscriptionState } from "../types/loginSubscription";
import { MobilePaymentState } from "../types/mobilePayment";
import { PayByMeState } from "../types/payByMe";
import { PollState } from "../types/poll";
import { ProfileState } from "../types/profile";
import { SignupInfoState } from "../types/signupInfo";
import { SignupMsisdnState } from "../types/signupMsisdn";
import { TestState } from "../types/test";
import { WheelState } from "../types/wheel";
import appReducer from "./app";
import campaignReducer from "./campaign";
import contactUsReducer from "./contactUs";
import emailConfirmReducer from "./emailConfirm";
import identityInfoReducer from "./identityInfo";
import jobReducer from "./job";
import loginMsisdnReducer from "./loginMsisdn";
import loginOtpReducer from "./loginOtp";
import loginSubscriptionReducer from "./loginSubscription";
import mobilePaymentReducer from "./mobilePayment";
import payByMeReducer from "./payByMe";
import pollReducer from "./poll";
import profileReducer from "./profile";
import signupInfoReducer from "./signupInfo";
import signupMsisdnReducer from "./signupMsisdn";
import testReducer from "./test";
import wheelReducer from "./wheel";

export interface IStore {
  app: AppState;
  identityInfo: IdentityInfoState;
  loginMsisdn: LoginMsisdnState;
  loginOtp: LoginOtpState;
  loginSubscription: LoginSubscriptionState;
  signupInfo: SignupInfoState;
  signupMsisdn: SignupMsisdnState;
  mobilePayment: MobilePaymentState;
  payByMe: PayByMeState;
  campaign: CampaignState;
  contactUs: ContactUsState;
  poll: PollState;
  profile: ProfileState;
  emailConfirm: EmailConfirmState;
  test: TestState;
  wheel: WheelState;
  job: JobState;
}

export const rootReducer: Reducer<CombinedState<IStore>> = combineReducers({
  app: appReducer,
  identityInfo: identityInfoReducer,
  loginMsisdn: loginMsisdnReducer,
  loginOtp: loginOtpReducer,
  loginSubscription: loginSubscriptionReducer,
  signupInfo: signupInfoReducer,
  signupMsisdn: signupMsisdnReducer,
  mobilePayment: mobilePaymentReducer,
  payByMe: payByMeReducer,
  campaign: campaignReducer,
  contactUs: contactUsReducer,
  poll: pollReducer,
  profile: profileReducer,
  emailConfirm: emailConfirmReducer,
  test: testReducer,
  wheel: wheelReducer,
  job: jobReducer,
});
