import {
  SIGNUP_MSISDN_SUBMIT_FAIL,
  SIGNUP_MSISDN_SUBMIT_RESET,
  SIGNUP_MSISDN_SUBMIT_START,
  SIGNUP_MSISDN_SUBMIT_SUCCESS
} from "../constants/signupMsisdn";
import {SignupMsisdnActions, SignupMsisdnState} from "../types/signupMsisdn";

const initialState: SignupMsisdnState = {
  submit: {
    loading: false,
    data: undefined,
    error: undefined,
  }
}

const reducer = (
  state = initialState,
  action: SignupMsisdnActions,
): SignupMsisdnState => {
  switch (action.type) {
    case SIGNUP_MSISDN_SUBMIT_START:
      return {
        ...state,
        submit: {
          ...initialState.submit,
          loading: true,
        }
      }
    case SIGNUP_MSISDN_SUBMIT_SUCCESS:
      return {
        ...state,
        submit: {
          ...state.submit,
          loading: false,
          data: action.payload,
        }
      }
    case SIGNUP_MSISDN_SUBMIT_FAIL:
      return {
        ...state,
        submit: {
          ...state.submit,
          loading: false,
          error: action.error,
        }
      }
    case SIGNUP_MSISDN_SUBMIT_RESET:
      return {
        ...state,
        submit: {
          ...initialState.submit,
        }
      }
    default:
      return state;
  }
}

export default reducer;
