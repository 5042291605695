import axios, {AxiosRequestConfig, AxiosResponse} from "axios";
import {router} from "./router";
import {getStorage, removeStorage, storageToken} from "./storage";

axios.interceptors.request.use((req: AxiosRequestConfig) => {
  const token: string | null = getStorage(storageToken);
  if (token) {
    req.headers.Authorization = `Bearer ${token}`;
  }
  return req;
});

axios.interceptors.response.use(
  (response: AxiosResponse) => {
    return response;
  },
  (error) => {
    const status: number = error.response?.status;
    if (status === 403) {
      removeStorage(storageToken);
      window.location.href = router.LOGIN_MSISDN;
    }
    return Promise.reject(error);
  }
);

export default axios;
