import React from "react";
import "./FanCard.scss";

interface IProps {
  firstName?: string;
  lastName?: string;
  cardNumber?: string;
}

const FanCard = (props: IProps) => {

  return (
    <div id="fan-card">
      <img
        src="https://s3-eu-west-1.amazonaws.com/static.4p/fenerbahce-taraftar-kart-.png"
        alt="fan-card"
      />
      {/*<div className="user-info-wrapper">*/}
      {/*  <span className="fullName">*/}
      {/*    {props?.firstName}&nbsp;*/}
      {/*    {props?.lastName}*/}
      {/*  </span>*/}
      {/*  <span className="cardNumber">*/}
      {/*    {props?.cardNumber}*/}
      {/*  </span>*/}
      {/*</div>*/}
    </div>
  );
}

export default FanCard;
