export const storageToken: string = 'token';
export const storageVposResult: string = 'vpos';
export const storageFcmToken: string = 'fcmToken';
export const storageRecaptcha: string = 'recaptcha';

export const getStorage = (key: string) => {
  return localStorage.getItem(key);
};
export const setStorage = (key: string, value: string) => {
  return localStorage.setItem(key, value);
};
export const removeStorage = (key: string) => {
  return localStorage.removeItem(key);
};
