import React from "react";
import { connect } from "react-redux";
import { bindActionCreators, Dispatch } from "redux";
import AuthHeader from "../../../../components/Auth/AuthHeader/AuthHeader";
import CreateCardText from "../../../../components/Auth/CreateCardText/CreateCardText";
import FanCard from "../../../../components/Auth/FanCard/FanCard";
import WelcomeText from "../../../../components/Auth/WelcomeText/WelcomeText";
import { setLoginMsisdn } from "../../../../core/actions/loginMsisdn";
import { signupMsisdnSubmit } from "../../../../core/actions/signupMsisdn";
import { history } from "../../../../core/helpers/history";
import { router } from "../../../../core/helpers/router";
import { FormValuesSignupMsisdn } from "../../../../core/models/custom/formValuesSignupMsisdn";
import { SignupInfoRequest } from "../../../../core/models/custom/signupInfo.request";
import { ErrorDto } from "../../../../core/models/dtos/error.dto";
import { TokenDto } from "../../../../core/models/dtos/token.dto";
import { SignupRequest } from "../../../../core/models/requests/signup.request";
import { IStore } from "../../../../core/reducers";
import AuthService from "../../../../core/services/auth.service";
import SignupMsisdnForm from "./SignupMsisdnForm/SignupMsisdnForm";
import "./SignupMsisdnPage.scss";

interface IProps {
  storeSignupInfoData?: SignupInfoRequest;
  loadingSubmit: boolean;
  dataSubmit?: TokenDto;
  errorSubmit?: ErrorDto;
  signupMsisdnSubmit: (request: SignupRequest) => void;
  setLoginMsisdn: (msisdn: string) => void;
}

interface IState {
  isMsisdnError: boolean;
  isOtpError: boolean;
  msisdn?: string;
}

class SignupMsisdnPage extends React.Component<IProps> {
  state: IState = {
    isMsisdnError: false,
    isOtpError: false,
    msisdn: undefined,
  };

  private authService: AuthService = new AuthService();
  private readonly errorMsisdn =
    "YANLIŞ BİR NUMARA GİRDİNİZ, LÜTFEN KONTROL EDİP TEKRAR DENEYİNİZ.";
  private readonly errorOtp = "BEKLENMEYEN BİR HATA OLUŞTU.";

  constructor(props: IProps) {
    super(props);
    if (!props.storeSignupInfoData) {
      history.push(router.SIGNUP_INFO);
    }
  }

  componentDidUpdate(
    prevProps: Readonly<IProps>,
    prevState: Readonly<{}>,
    snapshot?: any
  ) {
    if (prevProps.loadingSubmit && !this.props.loadingSubmit) {
      if (this.props.dataSubmit) {
        // history.push(router.MOBILE_PAYMENT);
        history.push(router.PAY_BY_ME); // Default payment VPOS
      } else {
        const msisdn = this.state.msisdn;
        if (!msisdn) {
          alert("Msisdn must be present");
          return;
        }
        const msisdnWithPrefix = `90${msisdn}`;
        this.authService
          .sendOtp({ msisdn: msisdnWithPrefix })
          .then(() => {
            this.props.setLoginMsisdn(msisdnWithPrefix);
            history.push(router.LOGIN_OTP);
          })
          .catch(() => {
            this.setState({ isOtpError: true });
          });
      }
    }
  }

  private getError(): string | undefined {
    if (this.state.isMsisdnError) {
      return this.errorMsisdn;
    } else if (this.state.isOtpError) {
      return this.errorOtp;
    }
    return undefined;
  }

  private async handleSubmit(values: FormValuesSignupMsisdn): Promise<void> {
    if (!this.props.storeSignupInfoData) {
      alert("Signup info must be present");
      return;
    }
    this.setState({
      isMsisdnError: false,
      isOtpError: false,
      msisdn: values.msisdn,
    });
    const request: SignupRequest = {
      country: this.props.storeSignupInfoData?.country,
      firstName: this.props.storeSignupInfoData?.firstName,
      lastName: this.props.storeSignupInfoData?.lastName,
      msisdn: `90${values.msisdn}`,
      // todo handle this if this page will be activated again
      marketingConsent: false,
      privacyConsent: false,
    };
    await this.props.signupMsisdnSubmit(request);
  }

  render() {
    return (
      <div id="signup-msisdn-page">
        <AuthHeader error={this.getError()} />
        <div className="container">
          <div className="page-content">
            <CreateCardText />
            <FanCard
              firstName={this.props.storeSignupInfoData?.firstName}
              lastName={this.props.storeSignupInfoData?.lastName}
            />
            <WelcomeText />
            <SignupMsisdnForm
              isSubmitDisabled={this.props.loadingSubmit}
              callbackSubmit={(values) => this.handleSubmit(values)}
              callbackError={() => this.setState({ isMsisdnError: true })}
            />
          </div>
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch: Dispatch) => {
  return bindActionCreators(
    {
      signupMsisdnSubmit,
      setLoginMsisdn,
    },
    dispatch
  );
};
const mapStateToProps = (store: IStore) => {
  const state = store.signupMsisdn;
  return {
    storeSignupInfoData: store.signupInfo.data,
    loadingSubmit: state.submit.loading,
    dataSubmit: state.submit.data,
    errorSubmit: state.submit.error,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SignupMsisdnPage);
