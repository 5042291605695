import React, {ReactNode} from "react";
import {connect} from "react-redux";
import {bindActionCreators, Dispatch} from "redux";
import AuthHeaderBottom from "../../../../components/Auth/AuthHeaderBottom/AuthHeaderBottom";
import BodyLoading from "../../../../components/Auth/BodyLoading/BodyLoading";
import FailedSubscription from "../../../../components/Auth/FailedSubscription/FailedSubscription";
import FanCard from "../../../../components/Auth/FanCard/FanCard";
import WelcomeText from "../../../../components/Auth/WelcomeText/WelcomeText";
import {logout} from "../../../../core/actions/app";
import {loginSubscriptionStatusCheck} from "../../../../core/actions/loginSubscription";
import {history} from "../../../../core/helpers/history";
import {router} from "../../../../core/helpers/router";
import {ErrorDto} from "../../../../core/models/dtos/error.dto";
import {UserMeDto} from "../../../../core/models/dtos/userMe.dto";
import {SubscriptionState} from "../../../../core/models/enums/subscriptionState";
import {IStore} from "../../../../core/reducers";
import "./LoginSubscriptionPage.scss";

interface IProps {
  storeUserMe?: UserMeDto;
  isCreditCardRedirect: boolean;
  loadingStatus: boolean;
  dataStatus?: SubscriptionState;
  errorStatus?: ErrorDto;
  loginSubscriptionStatusCheck: (isCreditCardRedirect: boolean) => void;
  logout: () => void;
}

class LoginSubscriptionPage extends React.Component<IProps> {

  componentDidMount() {
    this.props.loginSubscriptionStatusCheck(this.props.isCreditCardRedirect);
  }

  private handleRedirectCreditCard(): void {
    history.push(router.PAY_BY_ME);
  }

  private handleLogout(): void {
    this.props.logout();
    history.push(router.LOGIN_MSISDN);
  }

  private renderBody(): ReactNode {
    if (this.props.dataStatus === SubscriptionState.PENDING) {
      return (
        <React.Fragment>
          <FanCard
            firstName={this.props.storeUserMe?.firstName}
            lastName={this.props.storeUserMe?.lastName}
            cardNumber={this.props.storeUserMe?.cardNumber}
          />
          <WelcomeText/>
          <FailedSubscription
            callbackRetry={() => this.props.loginSubscriptionStatusCheck(this.props.isCreditCardRedirect)}
            callbackRedirectCreditCard={() => this.handleRedirectCreditCard()}
            callbackLogout={() => this.handleLogout()}
          />
        </React.Fragment>
      );
    }
    return <React.Fragment/>;
  }

  render() {
    return (
      <div id="login-subscription-page">
        <div className="container">
          <AuthHeaderBottom/>
          <div className="page-content">
            {
              this.props.loadingStatus
                ? <BodyLoading/>
                : this.renderBody()
            }
          </div>
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch: Dispatch) => {
  return bindActionCreators(
    {
      loginSubscriptionStatusCheck,
      logout,
    },
    dispatch
  );
};
const mapStateToProps = (store: IStore) => {
  const state = store.loginSubscription;
  return {
    storeUserMe: store.app.userMe.data,
    isCreditCardRedirect: store.mobilePayment.creditCardRedirect || store.loginMsisdn.code.data,
    loadingStatus: state.status.loading,
    dataStatus: state.status.data,
    errorStatus: state.status.error,
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(LoginSubscriptionPage);
