import {
  COPY_CARD_EVENT_FAIL,
  COPY_CARD_EVENT_RESET,
  COPY_CARD_EVENT_START,
  COPY_CARD_EVENT_SUCCESS,
} from "../constants/job";
import { JobActions, JobState } from "../types/job";

const initialState: JobState = {
  copyCardEvent: {
    loading: false,
    data: false,
    error: undefined,
  },
};

const reducer = (state = initialState, action: JobActions): JobState => {
  switch (action.type) {
    case COPY_CARD_EVENT_START:
      return {
        ...state,
        copyCardEvent: {
          ...initialState.copyCardEvent,
          loading: true,
        },
      };
    case COPY_CARD_EVENT_SUCCESS:
      return {
        ...state,
        copyCardEvent: {
          ...state.copyCardEvent,
          loading: false,
          data: action.payload,
        },
      };
    case COPY_CARD_EVENT_FAIL:
      return {
        ...state,
        copyCardEvent: {
          ...state.copyCardEvent,
          loading: false,
          error: action.error,
        },
      };
    case COPY_CARD_EVENT_RESET:
      return {
        ...initialState,
        copyCardEvent: {
          ...initialState.copyCardEvent,
        },
      };
    default:
      return state;
  }
};

export default reducer;
