import {Dispatch} from "redux";
import {
  MOBILE_PAYMENT_CHARGING_REQUEST_FAIL,
  MOBILE_PAYMENT_CHARGING_REQUEST_START,
  MOBILE_PAYMENT_CHARGING_REQUEST_SUCCESS,
  MOBILE_PAYMENT_CREDIT_CARD_REDIRECT,
  MOBILE_PAYMENT_SMS_CONFIRMATION_CHECK_FAIL,
  MOBILE_PAYMENT_SMS_CONFIRMATION_CHECK_START,
  MOBILE_PAYMENT_SMS_CONFIRMATION_CHECK_SUCCESS
} from "../constants/mobilePayment";
import {Constants} from "../helpers/constants";
import {Utilities} from "../helpers/utilities";
import {ErrorDto, ErrorGeneric, getErrorDtoFromApiError} from "../models/dtos/error.dto";
import {MobilePaymentDto} from "../models/dtos/mobilePayment.dto";
import {MobilePaymentStatus} from "../models/enums/mobilePaymentStatus";
import AuthService from "../services/auth.service";
import FirebaseService from "../services/firebase.service";
import PaymentService from "../services/payment.service";
import UserService from "../services/user.service";
import {
  MobilePaymentChargingRequestFailAction,
  MobilePaymentChargingRequestStartAction,
  MobilePaymentChargingRequestSuccessAction,
  MobilePaymentCreditCardRedirectAction,
  MobilePaymentSmsConfirmationCheckFailAction,
  MobilePaymentSmsConfirmationCheckStartAction,
  MobilePaymentSmsConfirmationCheckSuccessAction
} from "../types/mobilePayment";
import {setAppVisibilityAction, setUserMeAction} from "./app";

const authService: AuthService = new AuthService();
const paymentService: PaymentService = new PaymentService();
const userService: UserService = new UserService();

const mobilePaymentChargingRequestStartAction = (): MobilePaymentChargingRequestStartAction => {
  return {
    type: MOBILE_PAYMENT_CHARGING_REQUEST_START,
  };
}
const mobilePaymentChargingRequestSuccessAction = (payload: MobilePaymentDto): MobilePaymentChargingRequestSuccessAction => {
  return {
    type: MOBILE_PAYMENT_CHARGING_REQUEST_SUCCESS,
    payload: payload,
  };
}
const mobilePaymentChargingRequestFailAction = (error: ErrorDto): MobilePaymentChargingRequestFailAction => {
  return {
    type: MOBILE_PAYMENT_CHARGING_REQUEST_FAIL,
    error: error,
  };
}
export const mobilePaymentChargingSubmit = () => async (dispatch: Dispatch) => {
  try {
    dispatch(mobilePaymentChargingRequestStartAction());
    const mobilePaymentDto = await paymentService.startPayment();
    dispatch(mobilePaymentChargingRequestSuccessAction(mobilePaymentDto));
  } catch (error) {
    dispatch(mobilePaymentChargingRequestFailAction(getErrorDtoFromApiError(error)));
  }
};

const mobilePaymentSmsConfirmationCheckStartAction = (): MobilePaymentSmsConfirmationCheckStartAction => {
  return {
    type: MOBILE_PAYMENT_SMS_CONFIRMATION_CHECK_START,
  };
}
const mobilePaymentSmsConfirmationCheckSuccessAction = (payload: boolean): MobilePaymentSmsConfirmationCheckSuccessAction => {
  return {
    type: MOBILE_PAYMENT_SMS_CONFIRMATION_CHECK_SUCCESS,
    payload: payload,
  };
}
const mobilePaymentSmsConfirmationCheckFailAction = (error: ErrorDto): MobilePaymentSmsConfirmationCheckFailAction => {
  return {
    type: MOBILE_PAYMENT_SMS_CONFIRMATION_CHECK_FAIL,
    error: error,
  };
}
export const mobilePaymentSmsConfirmationCheck = (smsCid: string) => async (dispatch: Dispatch) => {
  try {
    dispatch(mobilePaymentSmsConfirmationCheckStartAction());
    const mobilePaymentStatusDto = await paymentService.mobilePaymentStatus(smsCid);
    if (mobilePaymentStatusDto) {
      const status = mobilePaymentStatusDto.status;
      if (status === MobilePaymentStatus.SUCCESSFUL) {
        const userMeDto = await userService.getDetails();
        dispatch(setUserMeAction(userMeDto));
        FirebaseService.fireEvent(FirebaseService.event_payment_user_count_view_odeme_payment_successful);
        await Utilities.wait(5000);
        await authService.refresh();
        dispatch(setAppVisibilityAction(true));
      } else if (status === MobilePaymentStatus.DISABLED) {
        const clientSideError: ErrorDto = {
          path: '/payment/mobile/status',
          status: 400,
          message: Constants.errorCodeMobilePaymentClosed
        };
        dispatch(mobilePaymentSmsConfirmationCheckFailAction(clientSideError));
      } else if (status === MobilePaymentStatus.FAILED) {
        dispatch(mobilePaymentSmsConfirmationCheckFailAction(ErrorGeneric));
      } else {
        dispatch(mobilePaymentSmsConfirmationCheckSuccessAction(true));
      }
    } else {
      dispatch(mobilePaymentSmsConfirmationCheckFailAction(ErrorGeneric));
    }
  } catch (error) {
    dispatch(mobilePaymentSmsConfirmationCheckFailAction(getErrorDtoFromApiError(error)));
  }
};

const mobilePaymentCreditCardRedirectAction = (payload: boolean): MobilePaymentCreditCardRedirectAction => {
  return {
    type: MOBILE_PAYMENT_CREDIT_CARD_REDIRECT,
    payload: payload,
  };
}
export const mobilePaymentSetCreditCardRedirect = (isCreditCardRedirect: boolean) => (
  dispatch: Dispatch
) => {
  dispatch(mobilePaymentCreditCardRedirectAction(isCreditCardRedirect));
}
