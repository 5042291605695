import {EMAIL_CONFIRM_FETCH_FAIL, EMAIL_CONFIRM_FETCH_START, EMAIL_CONFIRM_FETCH_SUCCESS} from "../constants/emailConfirm";
import {EmailConfirmActions, EmailConfirmState} from "../types/emailConfirm";

const initialState: EmailConfirmState = {
  loading: false,
  error: undefined,
  success: false
}

const reducer = (
  state = initialState,
  action: EmailConfirmActions,
): EmailConfirmState => {
  switch (action.type) {
    case EMAIL_CONFIRM_FETCH_START:
      return {
        ...state,
        loading: true
      };
    case EMAIL_CONFIRM_FETCH_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true

      };
    case EMAIL_CONFIRM_FETCH_FAIL:
      return {
        ...state,
        error: action.error,
        loading: false
      };
    default:
      return state;
  }
}

export default reducer;
