import jwt_decode from "jwt-decode";
import moment from "moment";
import { SubscriptionPeriod } from "../../containers/Auth/SubscriptionPage/SubscriptionPage";
import { ErrorDto } from "../models/dtos/error.dto";
import { JwtDto } from "../models/dtos/jwt.dto";
import { PersonalityDto } from "../models/dtos/personality.dto";
import { PollDto } from "../models/dtos/poll.dto";
import { Role } from "../models/enums/role";
import { history } from "./history";
import { router } from "./router";
import { getStorage, storageToken } from "./storage";
import dayjs from "dayjs";

export class Utilities {
  private static alphabet: string[] = [
    "A",
    "B",
    "C",
    "D",
    "E",
    "F",
    "G",
    "H",
    "I",
    "J",
    "K",
    "L",
    "M",
    "N",
    "O",
    "P",
    "Q",
    "R",
    "S",
    "T",
    "U",
    "V",
    "W",
    "X",
    "Y",
    "Z",
  ];

  static wait = (ms: number = 1000) =>
    new Promise((resolve) => setTimeout(resolve, ms));

  static isEnvProd(): boolean {
    const env: string = `${process.env.REACT_APP_ENV}`;
    return env === "production";
  }

  /**
   * ECMAScript Case Transformation
   * https://coderrocketfuel.com/article/how-to-check-if-a-character-is-a-letter-using-javascript
   */
  static isCharacterALetter(
    character: string,
    isSpaceAcceptable: boolean = true
  ) {
    const isCharacterSpace = character === " ";
    if (isCharacterSpace) {
      return isSpaceAcceptable;
    }
    return character.toLowerCase() !== character.toUpperCase();
  }

  static isTcknValid(tckn: string): boolean {
    if (!tckn || tckn.length !== 11) {
      return false;
    } else {
      // 1-3-5-7-9. haneler toplamından, 2-4-6-8. haneleri çıkar.
      // 10'a böl, kalan sayı 10. haneyi verecek
      let odds = 0;
      let evens = 0;

      for (let i = 0; i <= 8; i += 2) odds += Number.parseInt(tckn[i]);

      for (let i = 1; i <= 7; i += 2) evens += Number.parseInt(tckn[i]);

      if ((7 * odds - evens) % 10 !== Number.parseInt(tckn[9])) return false;

      // 1-10. haneler toplamının 10'a bölümünden kalan, 11. haneyi verecek
      let total = 0;
      for (let i = 0; i <= 9; i++) {
        total += Number.parseInt(tckn[i]);
      }
      return total % 10 === Number.parseInt(tckn[10]);
    }
  }

  static getFirstNameFromFullName(fullName: string): string {
    const strArray = fullName.trim().split(" ");
    return strArray
      .slice(0, strArray.length - 1)
      .join(" ")
      .trim();
  }

  static getLastNameFromFullName(fullName: string): string {
    const strArray = fullName.trim().split(" ");
    return strArray[strArray.length - 1];
  }

  static getQueryParam(query: string): string | null {
    const searchParams: URLSearchParams = new URLSearchParams(
      history.location.search
    );
    return searchParams.get(query);
  }

  private static getJwt(token: string): JwtDto {
    return jwt_decode(token) as JwtDto;
  }

  static getUserId(token: string): number {
    const decodedJwt = this.getJwt(token);
    return decodedJwt.userId;
  }

  private static getAuthorities(token: string): Role[] {
    const decodedJwt = this.getJwt(token);
    return decodedJwt.authorities;
  }

  static isUserRoleSubscriber(): boolean {
    const token: string | null = getStorage(storageToken);
    if (!token) {
      return false;
    } else {
      return this.getAuthorities(token).includes(Role.ROLE_SUBSCRIBER);
    }
  }

  static isMsisdnTurkish(msisdn: string): boolean {
    if (msisdn) {
      if (msisdn.length >= 2) {
        return msisdn.slice(0, 2) === "90";
      }
    }
    return false;
  }

  static getMsisdnFromToken(): string | null {
    const token = getStorage(storageToken);
    if (!token) {
      alert("Token does not exist!");
      return null;
    }
    const decodedJwt = this.getJwt(token);
    const msisdn: string = decodedJwt.sub;
    if (!msisdn) {
      alert("Msisdn must be defined in jwt!");
      return null;
    }
    return msisdn;
  }

  static isCurrentPathPaymentRedirect(): boolean {
    return (
      window.location.pathname === router.PAY_BY_ME_SUCCESS ||
      window.location.pathname === router.PAY_BY_ME_FAILED
    );
  }

  static navigateToPayment(msisdn: string, isCreditCardRedirect = false): void {
    /** Default payment VPOS */
    // history.push(router.PAY_BY_ME);

    /** Payment Redirect */
    // if (isCreditCardRedirect || !Utilities.isMsisdnTurkish(msisdn)) {
    //   history.push(router.PAY_BY_ME);
    // } else {
    //   history.push(router.MOBILE_PAYMENT);
    // }

    history.push(router.SUBSCRIPTION);
  }

  static navigateToPayByMe = (period: SubscriptionPeriod): void => {
    history.push(`${router.PAY_BY_ME}/${period}`);
  };

  static navigateToTestResultPage = (): void => {
    history.push(`${router.TEST}?percent=100`);
  };

  static getLetterFromIndex(index: number): string {
    if (index > 25) return index.toString();
    return this.alphabet[index];
  }

  static getSocialShareText(personality?: PersonalityDto): string {
    if (!personality) return "";
    return `${personality.title}: ${personality.description}`;
  }

  static getSocialShareTextTwitter(personality?: PersonalityDto): string {
    if (!personality) return "";
    return `${personality.title}, Sen Nasıl Bir Fenerbahçelisin? taraftarkart.fenerbahce.org`;
  }

  static isPushTokenError(error: ErrorDto): boolean {
    return error.path === "/users/me/push-token";
  }

  static getTimeDifference(
    date: string,
    type: "minutes" | "hours" | "days"
  ): number {
    return moment(moment()).diff(date, type);
  }

  static getSortedPolls(polls: PollDto[]): PollDto[] {
    return polls.sort((a, b) => (a.id > b.id ? 1 : -1));
  }

  static isNoPaymentTime(): boolean {
    const now = dayjs();
    const startDate = dayjs("2024-01-18 00:00:00");
    const endDate = dayjs("2024-01-18 07:00:00");
    return +startDate <= +now && +now <= +endDate;
  }
}
