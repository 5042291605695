import {Button, Input} from "antd";
import parse from "html-react-parser";
import React, {ReactNode} from "react";
import {connect} from "react-redux";
import {bindActionCreators, Dispatch} from "redux";
import iconTick from "../../../../assets/images/tick.svg";
import {pollAnswerPost} from "../../../../core/actions/poll";
import {ErrorDto} from "../../../../core/models/dtos/error.dto";
import {PollDto} from "../../../../core/models/dtos/poll.dto";
import {PollOptionDto} from "../../../../core/models/dtos/pollOption.dto";
import {PollAnswerRequest} from "../../../../core/models/requests/pollAnswer.request";
import {IStore} from "../../../../core/reducers";
import FirebaseService from "../../../../core/services/firebase.service";
import PollResult from "../PollResult/PollResult";
import "./QuestionTab.scss";

interface IProps {
  poll?: PollDto;
  dataActivePoll?: PollDto[];
  loadingAnswer: boolean;
  dataAnswer?: PollDto;
  errorAnswer?: ErrorDto;
  pollAnswerPost: (quizId: number, request: PollAnswerRequest, currentPolls: PollDto[]) => void;
}

interface IState {
  selectedOptionId?: number;
  isFreeText: boolean;
  freeText: string;
}

class QuestionTab extends React.Component<IProps> {
  state: IState = {
    selectedOptionId: undefined,
    isFreeText: false,
    freeText: '',
  }

  private static readonly freeTextMaxLen = 255;

  private isAnswerValid(): boolean {
    return (
      !!this.state.selectedOptionId &&
      (!this.state.isFreeText || this.state.freeText.length > 0)
    );
  }

  private handleOptionClick(option: PollOptionDto): void {
    this.setState({
      selectedOptionId: option.id,
      isFreeText: option.freeText,
    });
  }

  private handleSend(): void {
    FirebaseService.fireEvent(FirebaseService.event_ref_count_question_clicked);
    if (this.props.poll && this.state.selectedOptionId) {
      const request: PollAnswerRequest = {
        optionId: this.state.selectedOptionId,
        text: this.state.isFreeText ? this.state.freeText : undefined,
      }
      this.props.pollAnswerPost(this.props.poll.id, request, this.props.dataActivePoll ?? []);
    }
  }

  private renderContent(): ReactNode {
    if (this.props.poll) {
      if (this.props.poll.answerId) {
        return <PollResult data={this.props.poll} isCounter={true}/>
      }
      return this.renderQuestion(this.props.poll);
    }
    return <p>Bir hata oluştu</p>
  }

  private renderQuestion(data: PollDto): ReactNode {
    return (
      <div className="poll-question-wrapper">
        <div className="poll-name">
          {data.name}
        </div>
        <p className="poll-question">
          {data.text}
        </p>
        <div className="poll-options">
          {data.options.map(option => this.renderOption(option))}
        </div>
        {
          this.state.isFreeText &&
          <Input.TextArea
            className="free-text"
            rows={5}
            autoFocus
            value={this.state.freeText}
            maxLength={QuestionTab.freeTextMaxLen}
            placeholder={FirebaseService.getValue(FirebaseService.option_other_ph)}
            onChange={event => this.setState({freeText: event.target.value})}
          />
        }
        <Button
          type="primary"
          className="send"
          disabled={!this.isAnswerValid()}
          onClick={() => this.handleSend()}
        >
          <img className="mr-1" src={iconTick} alt="tick"/>
          {parse(FirebaseService.getValue(FirebaseService.monthly_question_button))}
        </Button>
      </div>
    );
  }

  private renderOption(option: PollOptionDto): ReactNode {
    return (
      <div
        key={`option-${option.id}`}
        className="poll-option no-select"
        onClick={() => this.handleOptionClick(option)}
      >
        <div className={`option ${this.state.selectedOptionId === option.id ? 'selected' : ''}`}/>
        <span>
          {option.text === 'Other' ? FirebaseService.getValue(FirebaseService.option_other) : option.text}
        </span>
      </div>
    );
  }

  render() {
    return (
      <div id="poll-question-tab">
        {this.renderContent()}
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch: Dispatch) => {
  return bindActionCreators(
    {
      pollAnswerPost,
    },
    dispatch
  );
};

const mapStateToProps = (store: IStore) => {
  return {
    poll: store.poll.pollModalQuestion,
    dataActivePoll: store.poll.activePoll.data,
    loadingAnswer: store.poll.answer.loading,
    dataAnswer: store.poll.answer.data,
    errorAnswer: store.poll.answer.error,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(QuestionTab);
