export const FETCH_ACTIVE_POLL_START = 'FETCH_ACTIVE_POLL_START';
export const FETCH_ACTIVE_POLL_SUCCESS = 'FETCH_ACTIVE_POLL_SUCCESS';
export const FETCH_ACTIVE_POLL_FAIL = 'FETCH_ACTIVE_POLL_FAIL';
export const FETCH_ACTIVE_POLL_RESET = 'FETCH_ACTIVE_POLL_RESET';

export const FETCH_HISTORY_START = 'FETCH_HISTORY_START';
export const FETCH_HISTORY_SUCCESS = 'FETCH_HISTORY_SUCCESS';
export const FETCH_HISTORY_FAIL = 'FETCH_HISTORY_FAIL';
export const FETCH_HISTORY_RESET = 'FETCH_HISTORY_RESET';

export const SET_POLL_MODAL_INITIAL_TAB = 'SET_POLL_MODAL_INITIAL_TAB';

export const SET_POLL_MODAL_QUESTION = 'SET_POLL_MODAL_QUESTION';

export const POST_POLL_ANSWER_START = 'POST_POLL_ANSWER_START';
export const POST_POLL_ANSWER_SUCCESS = 'POST_POLL_ANSWER_SUCCESS';
export const POST_POLL_ANSWER_FAIL = 'POST_POLL_ANSWER_FAIL';
export const POST_POLL_ANSWER_RESET = 'POST_POLL_ANSWER_RESET';
