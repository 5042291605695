import React, {ReactNode} from "react";
import {connect} from "react-redux";
import {NavLink} from "react-router-dom";
import {bindActionCreators, Dispatch} from "redux";
import faq from "../../../assets/images/faq.svg";
import {Constants} from "../../../core/helpers/constants";
import {router} from "../../../core/helpers/router";
import {IStore} from "../../../core/reducers";
import "./AuthHeaderBottom.scss";

interface IProps {
  pathname: string;
}

const AuthHeaderBottom = (props: IProps) => {

  const isTextMobile = (): boolean => {
    return (
      props.pathname === router.LANDING ||
      props.pathname === router.FAQ
    );
  }

  const isPageFAQ = (): boolean => {
    return props.pathname === router.FAQ;
  }

  const getTextWrapperClass = (): string => {
    return !isTextMobile() ? 'd-none d-md-block' : 'd-block';
  }

  const renderTextFB = (): ReactNode => {
    return (
      <div className={getTextWrapperClass()}>
        <span className="hash-link d-block d-md-none">
          <span className="hash">#</span>Fenerbahçelinin
          <br/>
          <span>KartıTaraftarKart</span>
        </span>
        <span className="hash-link d-none d-md-block">
          <span className="hash">#</span>
          FenerbahçelininKartıTaraftarKart
        </span>
      </div>
    );
  }

  const renderTextSSS = (): ReactNode => {
    return (
      <div className={getTextWrapperClass()}>
        {
          isPageFAQ() &&
          <NavLink
            className="faq-link"
            to={router.HOME}
          >
            Ana sayfa
          </NavLink>
        }

        {
          !isPageFAQ() &&
          <NavLink
            className="faq-link"
            to={router.FAQ}
          >
            <img className="mr-1" src={faq} alt="faq"/>
            <span className="d-none d-lg-flex">Sıkça Sorulan Sorular</span>
            <span className="d-flex d-lg-none">SSS</span>
          </NavLink>
        }
      </div>
    );
  }

  return (
    <div id="auth-header-bottom">
      <div className="link-wrapper">
        <div className="placeholder"/>
        <hr className="line"/>
        {renderTextFB()}
      </div>

      <a
        className="logo-heading"
        href="https://www.fenerbahce.org"
        target="_blank"
        rel="noreferrer"
      >
        <img
          src={Constants.fenerLogo}
          alt="fbLogo"
        />
      </a>

      <div className="link-wrapper">
        <div className="placeholder"/>
        <hr className="line"/>
        {renderTextSSS()}
      </div>
    </div>
  );
}

const mapDispatchToProps = (dispatch: Dispatch) => {
  return bindActionCreators(
    {},
    dispatch
  );
};

const mapStateToProps = (store: IStore) => {
  return {
    pathname: store.app.pathname,
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(AuthHeaderBottom);
