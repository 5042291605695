import {Dispatch} from "redux";
import {CAMPAIGN_FETCH_FAIL, CAMPAIGN_FETCH_START, CAMPAIGN_FETCH_SUCCESS, CAMPAIGN_RESET} from "../constants/campaign";
import {CampaignDto} from "../models/dtos/campaign.dto";
import {ErrorDto, ErrorGeneric, getErrorDtoFromApiError} from "../models/dtos/error.dto";
import CampaignService from "../services/campaign.service";
import {
  CampaignFetchFailAction,
  CampaignFetchStartAction,
  CampaignFetchSuccessAction,
  CampaignResetAction
} from "../types/campaign";

const campaignService: CampaignService = new CampaignService();

/**
 * CAMPAIGN
 */
const campaignFetchStartAction = (): CampaignFetchStartAction => {
  return {
    type: CAMPAIGN_FETCH_START,
  }
}
const campaignFetchSuccessAction = (payload: CampaignDto): CampaignFetchSuccessAction => {
  return {
    type: CAMPAIGN_FETCH_SUCCESS,
    payload: payload,
  }
}
const campaignFetchFailAction = (error: ErrorDto): CampaignFetchFailAction => {
  return {
    type: CAMPAIGN_FETCH_FAIL,
    error: error,
  }
}
export const fetchCampaign = (id: number) => async (
  dispatch: Dispatch
) => {
  try {
    dispatch(campaignFetchStartAction());
    const campaign = await campaignService.getCampaign(id);
    if (campaign) {
      dispatch(campaignFetchSuccessAction(campaign));
    } else {
      dispatch(campaignFetchFailAction(ErrorGeneric));
    }
  } catch (error) {
    dispatch(campaignFetchFailAction(getErrorDtoFromApiError(error)));
  }
}

const campaignResetAction = (): CampaignResetAction => {
  return {
    type: CAMPAIGN_RESET,
  }
}
export const resetCampaign = () => (
  dispatch: Dispatch
) => {
  dispatch(campaignResetAction());
}
