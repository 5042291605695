// import badge from "../../../assets/images/fb-taraftar-card-badge.png";
import cardGrayWithBg from "../../../assets/images/fb-taraftart-card-gray-with-bg.png";
import cardGray from "../../../assets/images/fb-taraftart-card-gray.png";
import cardWithBg from "../../../assets/images/fb-taraftart-card-with-bg.png";
import card from "../../../assets/images/fb-taraftart-card.png";
import "./FbCard.scss";

interface IProps {
  isBG?: boolean;
  isGray?: boolean;
  isBadge?: boolean;
}

const FbCard = (props: IProps) => {
  return (
    <div
      id="fb-card"
      // className={`${props.isBadge ? "hasBadge" : ""}`}
    >
      {props.isBG ? (
        props.isGray ? (
          <img src={cardGrayWithBg} alt="card" />
        ) : (
          <img src={cardWithBg} alt="card" />
        )
      ) : props.isGray ? (
        <img src={cardGray} alt="card" />
      ) : (
        <img src={card} alt="card" />
      )}
      {/* {props.isBadge && <img className="badge" src={badge} alt="badge" />} */}
    </div>
  );
};

export default FbCard;
