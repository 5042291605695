import {Checkbox, Form, Input, Select} from "antd";
import React, {useState} from "react";
import flagTr from "../../../../../assets/images/flag-tr.svg";
import ButtonPrimary from "../../../../../components/Buttons/ButtonPrimary/ButtonPrimary";
import {Utilities} from "../../../../../core/helpers/utilities";
import {FormValuesSignupInfo} from "../../../../../core/models/custom/formValuesSignupInfo";
import ModalContract from "../ModalContract/ModalContract";
import "./SignupInfoForm.scss";

const {Option} = Select;

interface IProps {
  isSubmitDisabled: boolean;
  callbackSubmit: (values: FormValuesSignupInfo) => void;
  callbackError: (error: string) => void
}

const SignupInfoForm = (props: IProps) => {
  const [form] = Form.useForm();
  const [isContractVisible, setContractVisibility] = useState<boolean>(false);

  const errorFullName = 'İSİM SOYİSİM EKSİK VEYA HATALI LÜTFEN KONTROL EDİP TEKRAR DENEYİNİZ.';
  const errorCountry = 'LÜTFEN YAŞADIĞINIZ ÜLKEYİ SEÇİNİZ.';
  const errorAgreement = 'SÖZLEŞMEYİ ONAYLAMADINIZ. LÜTFEN KONTROL EDİP TEKRAR DENEYİNİZ.';

  const handleKeyPressFullName = (e: React.KeyboardEvent) => {
    if (!Utilities.isCharacterALetter(e.key)) {
      e.preventDefault();
      return false;
    }
  }

  const handleModalContract = (isApproved: boolean) => {
    if (isApproved) {
      form.setFieldsValue({
        agreement: true,
      });
    }
    setContractVisibility(false);
  }

  const handleFinish = (values: FormValuesSignupInfo) => {
    if (values.fullName.trim().split(" ").length < 2) {
      props.callbackError(errorFullName);
      return;
    }
    props.callbackSubmit(values);
  }

  const handleFinisFailed = (errorInfo: { values: FormValuesSignupInfo }): void => {
    if (!errorInfo.values.fullName) {
      props.callbackError(errorFullName);
    } else if (!errorInfo.values.country) {
      props.callbackError(errorCountry);
    } else {
      props.callbackError(errorAgreement);
    }
  }

  return (
    <Form
      id="signup-info-form"
      form={form}
      // todo remove this for multiple country selection
      initialValues={{
        country: "TR",
      }}
      onFinish={handleFinish}
      onFinishFailed={handleFinisFailed}
    >

      <p className="info">
        Lüften Taraftar Kartını yaratabilmemiz için aşağıdaki bilgileri eksizsiz gir.
      </p>

      <Form.Item
        name="fullName"
        rules={[
          {required: true, message: ''},
        ]}
      >
        <Input
          className="form-input"
          type="text"
          placeholder="İsim Soyisim"
          onKeyPress={handleKeyPressFullName}
        />
      </Form.Item>

      <Form.Item
        name="country"
        rules={[
          {required: true, message: ''},
        ]}
      >
        <Select
          placeholder="Yaşadığınız Ülke"
        >
          <Option value="TR">
            Türkiye
            <img src={flagTr} className="ml-3" alt="tr"/>
          </Option>
        </Select>
      </Form.Item>

      <div className="checkbox-wrapper">

        <Form.Item
          className="mr-1 mb-0"
          name="agreement"
          valuePropName="checked"
          rules={[
            {
              validator: (_, value) =>
                value ? Promise.resolve() : Promise.reject(''),
            },
          ]}
        >
          <Checkbox/>
        </Form.Item>

        <label className="checkbox-text">
          <span
            className="link"
            onClick={() => setContractVisibility(true)}
          >
            İletişim Sözleşmesini
          </span>
          &nbsp;Onaylıyorum.
        </label>
      </div>

      <div className="d-flex justify-content-center">
        <ButtonPrimary
          text="Devam Et"
          isSubmit={true}
          disabled={props.isSubmitDisabled}
        />
      </div>

      <ModalContract
        visible={isContractVisible}
        callbackOk={() => handleModalContract(true)}
        callbackCancel={() => handleModalContract(false)}
      />
    </Form>
  );
}

export default SignupInfoForm;
