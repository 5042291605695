import {Spin} from "antd";
import parse from "html-react-parser";
import React, {ReactNode} from "react";
import {connect} from "react-redux";
import {NavLink} from "react-router-dom";
import {bindActionCreators, Dispatch} from "redux";
import {fetchCampaign} from "../../../core/actions/campaign";
import {history} from "../../../core/helpers/history";
import {router} from "../../../core/helpers/router";
import {CampaignDto} from "../../../core/models/dtos/campaign.dto";
import {ErrorDto} from "../../../core/models/dtos/error.dto";
import {UserMeDto} from "../../../core/models/dtos/userMe.dto";
import {IStore} from "../../../core/reducers";
import FirebaseService from "../../../core/services/firebase.service";
import {CampaignState} from "../../../core/types/campaign";
import "./CampaignPage.scss";

interface IProps {
  match: any,
  userMe?: UserMeDto,
  loadingCampaign: boolean,
  dataCampaign?: CampaignDto,
  errorCampaign?: ErrorDto,
  fetchCampaign: (id: number) => void,
}

class CampaignPage extends React.Component<IProps> {

  private readonly campaignId: number;

  constructor(props: IProps) {
    super(props);
    this.campaignId = +this.props.match.params.id;
  }

  componentDidMount() {
    if (!isNaN(this.campaignId)) {
      this.props.fetchCampaign(this.campaignId);
    } else {
      history.push(router.HOME);
    }
  }

  private getTermsOfUse(): string[] {
    return this.props.dataCampaign?.termsOfUse.split("|") ?? [];
  }

  private isTcknButton(): boolean {
    const isRequired: boolean = this.props.dataCampaign?.tcknRequired === true;
    const isUserTckn: boolean = !!this.props.userMe?.tckn;
    const isUserFirstName: boolean = !!this.props.userMe?.firstName;
    const isUserLastName: boolean = !!this.props.userMe?.lastName;
    return isRequired && (!isUserTckn || !isUserFirstName || !isUserLastName);
  }

  private renderContent(): ReactNode {
    if (this.props.loadingCampaign) {
      return <Spin size="large" className="my-5"/>
    } else if (this.props.dataCampaign) {
      const campaign = this.props.dataCampaign;
      return (
        <div className="campaign">
          <img className="logo" src={campaign.logoUrl} alt="logo"/>
          <img className="image" src={campaign.imageUrl} alt="img"/>
          <h1 className="title">
            {parse(campaign.detailsTitle)}
          </h1>
          <span className="description">
            {parse(campaign.detailsDescription)}
          </span>
          {
            this.isTcknButton()
              ?
              <div className="tckn-required">
                <span className="tckn-error">
                  {parse(FirebaseService.getValue(FirebaseService.campaign_info_required_text))}
                </span>
                <NavLink
                  className="tckn-button"
                  to={router.PROFILE}
                  onClick={() => FirebaseService.fireEvent(FirebaseService.event_offer_user_count_ticket_offer_profile_button_clicked)}
                >
                  {parse(FirebaseService.getValue(FirebaseService.campaign_info_required_button))}
                </NavLink>
              </div>
              :
              <div className="tckn-required-placeholder"/>
          }
          <div className="info-wrapper">
            <label className="how-to-use-title">
              Nasıl Kullanırım:
            </label>
            <p className="how-to-use">
              {parse(campaign.howToUse)}
            </p>
            <div>
              <label className="terms-title">
                Kullanım Koşulları:
              </label>
              <ul className="terms">
                {this.getTermsOfUse().map((term, index) => {
                  return (
                    <li
                      key={`term-${index}`}
                      className="term"
                    >
                      {parse(term)}
                    </li>
                  );
                })}
              </ul>
            </div>
          </div>
        </div>
      );
    }
    return <React.Fragment/>;
  }

  render() {
    return (
      <div id="campaign-page" className="page">
        <div className="page-content">
          {this.renderContent()}
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch: Dispatch) => {
  return bindActionCreators(
    {
      fetchCampaign,
    },
    dispatch
  );
};

const mapStateToProps = (store: IStore) => {
  const state: CampaignState = store.campaign;
  return {
    userMe: store.app.userMe.data,
    loadingCampaign: state.campaign.loading,
    dataCampaign: state.campaign.data,
    errorCampaign: state.campaign.error,
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(CampaignPage);
