import {Form, Input, Select} from "antd";
import React from "react";
import ButtonPrimary from "../../../components/Buttons/ButtonPrimary/ButtonPrimary";
import {Utilities} from "../../../core/helpers/utilities";
import {FormValuesContactUsPrivate} from "../../../core/models/custom/formValuesContactUsPrivate";
import FirebaseService from "../../../core/services/firebase.service";
import {IFormInitialValues} from "../ContactUsPrivatePage";
import "./ContactUsPrivateForm.scss";

const {Option} = Select;
const {TextArea} = Input;

interface IProps {
  initialValues: IFormInitialValues,
  isSubmitDisabled: boolean;
  callbackSubmit: (values: FormValuesContactUsPrivate) => void;
  callbackError: () => void
}

const ContactUsPrivateForm = (props: IProps) => {

  const getSubjects = (): string[] => {
    return FirebaseService.getValue(FirebaseService.contact_us_subject).split("|");
  }

  const handleKeyPressFullName = (e: React.KeyboardEvent) => {
    if (!Utilities.isCharacterALetter(e.key)) {
      e.preventDefault();
      return false;
    }
  }

  return (
    <Form
      id="contact-us-private-form"
      className="container"
      initialValues={props.initialValues}
      onFinish={values => props.callbackSubmit(values)}
      onFinishFailed={props.callbackError}
    >
      <div className="row">
        <div className="col-12 col-lg-6 form-item-wrapper">
          <Form.Item
            name="fullName"
            rules={[
              {required: true, message: ''},
            ]}
          >
            <Input
              className="form-input"
              type="text"
              placeholder="İsim Soyisim"
              onKeyPress={handleKeyPressFullName}
            />
          </Form.Item>
        </div>

        <div className="col-12 col-lg-6 form-item-wrapper">
          <Form.Item
            name="email"
            rules={[
              {required: true, message: ''},
              {type: "email", message: ''},
            ]}
          >
            <Input
              className="form-input"
              type="text"
              placeholder="E-mail"
            />
          </Form.Item>
        </div>
      </div>

      <div className="row">
        <div className="col-12 col-lg-6 form-item-wrapper">
          <Form.Item
            name="subject"
            rules={[
              {required: true, message: ''},
            ]}
          >
            <Select
              className="form-select"
              placeholder="Mesajın konusu"
            >
              {getSubjects().map((subject, index) => {
                return (
                  <Option
                    key={`subject-${index}`}
                    value={subject}
                  >
                    {subject}
                  </Option>
                );
              })}
            </Select>
          </Form.Item>
        </div>
      </div>

      <div className="row">
        <div className="col-12 form-item-wrapper">
          <Form.Item
            name="message"
            rules={[
              {required: true, message: ''},
            ]}
          >
            <TextArea
              className="text-area"
              rows={8}
              placeholder="Mesajını yaz"
            />
          </Form.Item>
        </div>
      </div>

      <div className="d-flex flex-column align-items-center mt-5">
        <ButtonPrimary
          text="Gönder"
          isSubmit={true}
          disabled={props.isSubmitDisabled}
        />
      </div>
    </Form>
  );
}

export default ContactUsPrivateForm;
