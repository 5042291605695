import { Button } from "antd";
import parse from "html-react-parser";
import React from "react";
import { history } from "../../core/helpers/history";
import { router } from "../../core/helpers/router";
import FirebaseService from "../../core/services/firebase.service";
import "./FaqPage.scss";

interface IProps {
}

interface IData {
    className: string;
    text: string;
}

class FaqPage extends React.Component<IProps> {

    componentDidMount() {
        FirebaseService.fireEvent(FirebaseService.event_user_count_view_faq);
    }

    getSortedAnswerQuestion(): IData[] {
        const questionArray:string[] = FirebaseService.getValue(FirebaseService.faq_questions).toString().split("|");
        const answerArray:string[] = FirebaseService.getValue(FirebaseService.faq_answers).toString().split("|");
        let faqData: IData[] = [];
        for(let i = 0; i < questionArray.length; i++) {
            faqData.push({ className: "title", text: questionArray[i] });
            faqData.push({ className: "text", text: answerArray[i] });
        }
        return faqData;
    }

    render() {
        return (
            <div id="faq-page" className="page">
                <div className="page-content">
                    <div className="section section-faq-title">
                        <div className="heading">
                            <div className="info">
                                <span className="title">
                                    {parse(FirebaseService.getValue(FirebaseService.faq_page_header))}
                                </span>
                                <span className="description">
                                    {parse(FirebaseService.getValue(FirebaseService.faq_page_description))}
                                </span>
                            </div>
                        </div>
                    </div>

                    <div className="section section-faq-content">
                        <div className="heading">
                            <div className="info">
                                {this.getSortedAnswerQuestion().map((faq, index) => (
                                    <div className="faq-items" key={index}>
                                        <span className={faq.className}>
                                            {parse(faq.text)}
                                        </span>
                                    </div>    
                                ))}
                            </div>
                        </div>
                    </div>

                    <div className="section section-faq-contact">
                        <div className="body">
                            <Button 
                                className="btn-faq-contact" 
                                type="link" 
                                onClick={()=> history.push(router.CONTACT_US)}>
                                Bize Ulaş
                            </Button>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default FaqPage;
