import {Form, Input} from "antd";
import React, {useState} from "react";
import paymentTelekom from "../../../../../assets/images/payment-telekom.svg";
import paymentTurkcell from "../../../../../assets/images/payment-turkcell.svg";
import paymentVodafone from "../../../../../assets/images/payment-vodafone.svg";
import ButtonPrimary from "../../../../../components/Buttons/ButtonPrimary/ButtonPrimary";
import {FormValuesSignupMsisdn} from "../../../../../core/models/custom/formValuesSignupMsisdn";
import "./SignupMsisdnForm.scss";

interface IProps {
  isSubmitDisabled: boolean;
  callbackSubmit: (values: FormValuesSignupMsisdn) => void;
  callbackError: () => void
}

const SignupMsisdnForm = (props: IProps) => {
  const [form] = Form.useForm();
  const [isSubmitted, setSubmitted] = useState<boolean>(false);

  const handleMsisdnChange = (msisdn: string) => {
    if (msisdn) {
      if (msisdn.substring(0, 1) !== "5") {
        form.setFieldsValue({msisdn: ''});
      }
      if (msisdn.length > 10) {
        form.setFieldsValue({msisdn: msisdn.substring(0, 10)});
      }
    }
  }

  const handleMsisdnKeyPress = (e: React.KeyboardEvent) => {
    if (e.key === "e") {
      e.preventDefault();
    }
  }

  const renderPayment = (href: string, src: any, alt: string) => {
    return (
      <a
        className="col-3"
        href={href}
        rel="noreferrer"
        target="_blank"
      >
        <img className="img-fluid" src={src} alt={alt}/>
      </a>
    );
  }

  const handleFinishFailed = () => {
    setSubmitted(true);
    props.callbackError();
  }

  return (
    <Form
      id="signup-msisdn-form"
      form={form}
      onFinish={values => props.callbackSubmit(values)}
      onFinishFailed={handleFinishFailed}
    >

      <Form.Item
        name="msisdn"
        rules={[
          {required: true, message: ''},
          {min: 10, message: ''},
        ]}
      >
        <Input
          className={`form-input ${!isSubmitted ? "no-error-border" : ""}`}
          type="number"
          placeholder="Cep Telefonu"
          onChange={e => handleMsisdnChange(e.target.value)}
          onKeyPress={handleMsisdnKeyPress}
        />
      </Form.Item>

      <p className="info">
        Lütfen telefon numaranızı başında sıfır olmadan 10 hane olarak giriniz.
        ÖRN: 5XX XXX XX XX
      </p>

      <p className="helper-1">
        _______ servisinin ücreti ___/haftalık'dir. 6662 numarasına gönderilen her SMS Turkcell aboneleri için 0,50 TL,
        Türk Telekom aboneleri için 0,50 TL ve Vodafone aboneleri için 0,41 TL olarak ücretlendirilir. Ödeme detayları
        faturalı hatlar
        için faturada belirtilecek, kontörlü hatlar için mevcut krediden düşecektir. Yeterli bakiyeniz olmaması
        durumunda tutar mevcut bakiyenizden çekilir, kalan tutar ise hattınıza TL yüklediğiniz zaman alınır. Bu
        abonelikli bir servistir. Aboneliğinizi iptal etmediğiniz sürece haftalık olarakyenilenecektir. Aboneliğinizin
        iptali için IPTAL ____ yazarak 6662'ye gönderiniz. Müşteri hizmetleri e posta adresi: sari@lacivert.org
      </p>

      <p className="helper-2">
        Mobil Ödeme hakkında detaylı bilgi için operatörünüzün mobil ödeme logosuna tıklayınız.
      </p>

      <div className="payment-wrapper">
        {renderPayment("https://www.turkcell.com.tr/servisler/turkcellmobilodeme", paymentTurkcell, "turkcell")}
        {renderPayment("https://www.vodafone.com.tr/Servisler/vodafone-mobil-odeme.php", paymentVodafone, "vodafone")}
        {renderPayment("https://bireysel.turktelekom.com.tr/mobil/web/servisler/sayfalar/turk-telekom-mobil-odeme.aspx", paymentTelekom, "telekom")}
      </div>

      <div className="d-flex justify-content-center">
        <ButtonPrimary
          text="Devam Et"
          isSubmit={true}
          disabled={props.isSubmitDisabled}
        />
      </div>

    </Form>
  );
}

export default SignupMsisdnForm;
