import axios from "../helpers/axios";
import {ContactUsRequest} from "../models/requests/contactUs.request";
import {ContactUsPrivateRequest} from "../models/requests/contactUsPrivate.request";

export default class MiscService {

  async contactUs(request: ContactUsRequest | ContactUsPrivateRequest): Promise<void> {
    try {
      const response = await axios.post<void>(
        `${process.env.REACT_APP_BASE_URL}/misc/contact-us`,
        {...request},
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  }
}
