import {Button, Spin} from "antd";
import parse from "html-react-parser";
import React, {ReactNode} from "react";
import {connect} from "react-redux";
import {bindActionCreators, Dispatch} from "redux";
import arrow from "../../../assets/images/arrow-right.svg";
import past from "../../../assets/images/past.svg";
import headingVoting from "../../../assets/images/section-heading-voting.svg";
import {pollActiveFetch, setPollModalInitialTab, setPollModalQuestion} from "../../../core/actions/poll";
import {ErrorDto} from "../../../core/models/dtos/error.dto";
import {PollDto} from "../../../core/models/dtos/poll.dto";
import {UserMeDto} from "../../../core/models/dtos/userMe.dto";
import {IStore} from "../../../core/reducers";
import FirebaseService from "../../../core/services/firebase.service";
import {PollModalInitialTab} from "../../Modals/PollModal/PollModal";
import "./VotingBanner.scss";

interface IProps {
  userMe?: UserMeDto;
  loadingActivePoll: boolean;
  dataActivePoll?: PollDto[];
  errorActivePoll?: ErrorDto;
  pollActiveFetch: () => void;
  setPollModalInitialTab: (initialTab?: PollModalInitialTab) => void;
  setPollModalQuestion: (poll?: PollDto) => void;
}

class VotingBanner extends React.Component<IProps> {

  componentDidMount() {
    this.props.pollActiveFetch();
  }

  private handlePollButton(initialTab: PollModalInitialTab, poll: PollDto): void {
    if (initialTab === PollModalInitialTab.HISTORY) {
      FirebaseService.fireEvent(FirebaseService.event_ref_count_past_question_button_clicked);
    } else {
      FirebaseService.fireEvent(FirebaseService.event_ref_count_start_button_clicked);
    }
    this.props.setPollModalInitialTab(initialTab);
    this.props.setPollModalQuestion(poll);
  }

  private renderContent(): ReactNode {
    if (this.props.loadingActivePoll) {
      return <Spin size="large"/>
    } else if (this.props.dataActivePoll && this.props.dataActivePoll.length > 0) {
      return this.renderBanner(this.props.dataActivePoll);
    }
    return <React.Fragment/>
  }

  private renderOneQuestion(poll: PollDto): ReactNode {
    return (
      <div className="voting-body">
        <div className="title">
          {poll.name}
        </div>
        <div className="question">
          {poll.text}
        </div>
        {
          poll.answerId
            ?
            <div className="answered-vote">
              <div className="counter">
                {parse(FirebaseService.getValue(FirebaseService.answered_question_new_question_counter_description))}
                <div className="count">
                  {poll.daysRemaining} Gün
                </div>
              </div>
              <Button
                className="button ml-auto"
                type="primary"
                onClick={() => this.handlePollButton(PollModalInitialTab.HISTORY, poll)}
              >
                <img className="mr-1" src={past} alt="past"/>
                {
                  window.innerWidth < 768
                    ? parse(FirebaseService.getValue(FirebaseService.past_question_button_mobile))
                    : parse(FirebaseService.getValue(FirebaseService.past_question_button_desktop))
                }
              </Button>
            </div>
            :
            <Button
              className="button ml-auto"
              type="primary"
              onClick={() => this.handlePollButton(PollModalInitialTab.QUESTION, poll)}
            >
              {parse(FirebaseService.getValue(FirebaseService.referandum_button))}
              <img className="ml-1" src={arrow} alt="arrow"/>
            </Button>
        }
      </div>
    );
  }

  private renderMultipleQuestion(polls: PollDto[]): ReactNode {
    const unansweredQuestionNumber = polls.filter(p => !p.answerId).length;
    const answeredQuestionNumber = polls.filter(p => !!p.answerId).length;
    let key = '';
    if (answeredQuestionNumber === polls.length) {
      key = FirebaseService.referandum_all_answered;
    } else if (unansweredQuestionNumber === polls.length) {
      key = FirebaseService.referandum_not_yet_answered;
    } else {
      key = FirebaseService.referandum_at_least_one_answered;
    }
    return (
      <div className="voting-body">
        <div className="question">
          {parse(FirebaseService.getValue(key))}
        </div>
        <div className="button-wrapper">
          {polls.map((poll, index) => {
            return (
              <Button
                key={`voting-button-${index}`}
                className="button"
                type="primary"
                disabled={!!poll.answerId}
                onClick={() => this.handlePollButton(PollModalInitialTab.QUESTION, poll)}
              >
                {parse(poll.name)}
              </Button>
            );
          })}
        </div>
      </div>
    );
  }

  private renderBanner(polls: PollDto[]): ReactNode {
    return (
      <React.Fragment>
        <div className="heading">
          <img src={headingVoting} alt="voting"/>
          <div className="info">
            <span className="title">
              {parse(FirebaseService.getValue(FirebaseService.referandum_header))}
            </span>
            <span className="text">
              {parse(FirebaseService.getValue(FirebaseService.referandum_description))}
            </span>
          </div>
        </div>
        {
          polls.length === 1
            ? this.renderOneQuestion(polls[0])
            : this.renderMultipleQuestion(polls)
        }
      </React.Fragment>
    );
  }

  render() {
    return (
      <div id="voting-banner" className="section">
        {this.renderContent()}
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch: Dispatch) => {
  return bindActionCreators(
    {
      pollActiveFetch,
      setPollModalInitialTab,
      setPollModalQuestion,
    },
    dispatch
  );
};

const mapStateToProps = (store: IStore) => {
  return {
    userMe: store.app.userMe.data,
    loadingActivePoll: store.poll.activePoll.loading,
    dataActivePoll: store.poll.activePoll.data,
    errorActivePoll: store.poll.activePoll.error,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(VotingBanner);
