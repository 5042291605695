import axios from "../helpers/axios";

export default class JobService {
  async copyCardEvent(): Promise<void> {
    try {
      const response = await axios.post<void>(
        `${process.env.REACT_APP_BASE_URL}/job/copy-card-number-event`
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  }
}
