import React, {ReactNode} from 'react';
import {NavLink} from 'react-router-dom';
import arrowBlue from "../../../assets/images/notification-button-arrow-blue.svg";
import arrowWhite from "../../../assets/images/notification-button-arrow-white.svg";
import {Utilities} from "../../../core/helpers/utilities";
import {NotificationDto} from "../../../core/models/dtos/notification.dto";
import "./NotificationCard.scss";

interface IProps {
  notification: NotificationDto;
}

const NotificationCard = (props: IProps) => {

  const getCardStyle = (): React.CSSProperties => {
    return props.notification.isMarked
      ? {background: `url(${props.notification.image}) no-repeat center`, backgroundSize: "cover"}
      : {backgroundColor: "white"};
  }

  const getTime = (): string => {
    const minutes = Utilities.getTimeDifference(props.notification.date, "minutes");
    const hours = Utilities.getTimeDifference(props.notification.date, "hours");
    const days = Utilities.getTimeDifference(props.notification.date, "days");
    if (days > 0) {
      return days === 1 ? 'Dün' : `${days} gün önce`;
    } else if (hours > 0) {
      return `${hours} saat önce`;
    } else if (minutes > 0) {
      return `${minutes} dakika önce`;
    } else {
      return 'Az önce';
    }
  }

  const isRouterLink = (link: string): boolean => {
    return !link.startsWith("http");
  }

  const renderImage = (): ReactNode => {
    return !props.notification.isMarked && props.notification.image
      ? <img className="image" src={props.notification.image} alt="preview"/>
      : <React.Fragment/>;
  }

  const renderHeader = (): ReactNode => {
    return (
      <span className="header">
        {props.notification.header}
      </span>
    );
  }

  const renderDescription = (): ReactNode => {
    return (
      <span className="description">
        {props.notification.desc}
      </span>
    );
  }

  const renderFooter = (): ReactNode => {
    return (
      <div className="footer">
        <span className="date">
          {getTime()}
        </span>
        {
          (props.notification.buttonLink) &&
          (
            isRouterLink(props.notification.buttonLink)
              ?
              <NavLink
                className="link-button"
                to={props.notification.buttonLink}
              >
                {props.notification.buttonText ?? 'Git'}
                <img
                  src={props.notification.isMarked ? arrowWhite : arrowBlue}
                  className="arrow" alt="arrow"
                />
              </NavLink>
              :
              <a
                className="link-button"
                href={props.notification.buttonLink} target="_blank" rel="noreferrer"
              >
                {props.notification.buttonText ?? 'Git'}
                <img
                  src={props.notification.isMarked ? arrowWhite : arrowBlue}
                  className="arrow" alt="arrow"
                />
              </a>
          )
        }
      </div>
    );
  }

  return (
    <div
      id="notification-card"
      className={props.notification.isMarked ? 'marked' : ''}
      style={getCardStyle()}
    >
      <div className="left-indicator"/>
      <div className="content">
        {renderImage()}
        {renderHeader()}
        {renderDescription()}
        {renderFooter()}
      </div>
    </div>
  );
}

export default NotificationCard;
