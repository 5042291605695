import {Button, Modal} from "antd";
import React from "react";
import {connect} from "react-redux";
import {bindActionCreators, Dispatch} from "redux";
import iconError from "../../../assets/images/popup-icon-error.svg";
import {resetApiError} from "../../../core/actions/app";
import {Constants} from "../../../core/helpers/constants";
import {Utilities} from "../../../core/helpers/utilities";
import {ErrorDto} from "../../../core/models/dtos/error.dto";
import {IStore} from "../../../core/reducers";
import FirebaseService from "../../../core/services/firebase.service";
import "./ApiErrorModal.scss";

interface IProps {
  apiError?: ErrorDto,
  resetApiError: () => void,
}

const ApiErrorModal = (props: IProps) => {

  const errorInvalidOtpTitle = 'Onay Kodunu Yanlış Girdin!';
  const errorInvalidOtpText = 'Sana gönderdiğimiz kodu tekrar gir!';
  const errorInvalidMsisdnTitle = 'Yanlış bir numara girdin!';
  const errorInvalidMsisdnText = 'Lütfen doğru numarayı tekrar gir!';
  const errorInvalidEmailTitle = 'Geçersiz Email';
  const errorInvalidEmailText = 'Lütfen email adresini kontrol et!';

  const isErrorDialog = (): boolean => {
    return (
      !!props.apiError &&
      !Utilities.isPushTokenError(props.apiError)
    );
  }

  const isOTPError = (apiError: ErrorDto): boolean => {
    return apiError.path === '/auth/login' && apiError.message === Constants.errorCodeUnauthorized;
  }

  const isMsisdnError = (apiError: ErrorDto): boolean => {
    return apiError.path === '/auth/sign-up' && apiError.message === Constants.errorCodeWrongMsisdnFormat;
  }

  const getTitle = (): string => {
    if (props.apiError) {

      if (isOTPError(props.apiError)) {
        return errorInvalidOtpTitle;
      }

      if (isMsisdnError(props.apiError)) {
        return errorInvalidMsisdnTitle;
      }

      switch (props.apiError.message) {
        case Constants.errorCodeTcknNotUnique:
          return FirebaseService.getValue(FirebaseService.api_error_title_tckn_not_unique);
        case Constants.errorCodeInvalidEmail:
          return errorInvalidEmailTitle;
        default:
          return Constants.errorGenericTitle;
      }
    }
    return '';
  }

  const getText = (): string => {
    if (props.apiError) {

      if (isOTPError(props.apiError)) {
        return errorInvalidOtpText;
      }

      if (isMsisdnError(props.apiError)) {
        return errorInvalidMsisdnText;
      }

      switch (props.apiError.message) {
        case Constants.errorCodeTcknNotUnique:
          return FirebaseService.getValue(FirebaseService.api_error_text_tckn_not_unique);
        case Constants.errorCodeTcknMissingDetails:
          return FirebaseService.getValue(FirebaseService.name_surname_bdate_required);
        case Constants.errorCodeTcknDetailsMismatch:
          return FirebaseService.getValue(FirebaseService.profile_info_does_not_match);
        case Constants.errorCodeInvalidEmail:
          return errorInvalidEmailText;
        default:
          return Constants.errorGenericText;
      }
    }
    return '';
  }

  return (
    <Modal
      className="api-error-modal"
      maskStyle={{backdropFilter: "blur(8px)"}}
      visible={isErrorDialog()}
      centered
      closable={false}
      footer={false}
      onCancel={props.resetApiError}
      destroyOnClose
    >
      <div className="body">

        <div className="header">
          <span className="title">{getTitle()}</span>
          <img className="logo" src={iconError} alt="error"/>
        </div>

        <p className="text">{getText()}</p>

        <Button
          className="button"
          onClick={props.resetApiError}
        >
          Tamam
        </Button>
      </div>

    </Modal>
  );
}

const mapDispatchToProps = (dispatch: Dispatch) => {
  return bindActionCreators(
    {
      resetApiError,
    },
    dispatch
  );
};
const mapStateToProps = (store: IStore) => {
  const state = store.app;
  return {
    apiError: state.apiError,
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(ApiErrorModal);
