import {Dispatch} from "redux";
import {
  CONTACT_US_SUBMIT_FAIL,
  CONTACT_US_SUBMIT_RESET,
  CONTACT_US_SUBMIT_START,
  CONTACT_US_SUBMIT_SUCCESS,
} from "../constants/contactUs";
import {ErrorDto, getErrorDtoFromApiError} from "../models/dtos/error.dto";
import {ContactUsRequest} from "../models/requests/contactUs.request";
import {ContactUsPrivateRequest} from "../models/requests/contactUsPrivate.request";
import FirebaseService from "../services/firebase.service";
import MiscService from "../services/misc.service";
import {
  ContactUsSubmitFailAction,
  ContactUsSubmitResetAction,
  ContactUsSubmitStartAction,
  ContactUsSubmitSuccessAction,
} from "../types/contactUs";

const miscService: MiscService = new MiscService();

const contactUsSubmitStartAction = (): ContactUsSubmitStartAction => {
  return {
    type: CONTACT_US_SUBMIT_START,
  };
}
const contactUsSubmitSuccessAction = (payload: boolean): ContactUsSubmitSuccessAction => {
  return {
    type: CONTACT_US_SUBMIT_SUCCESS,
    payload: payload,
  };
}
const contactUsSubmitFailAction = (error: ErrorDto): ContactUsSubmitFailAction => {
  return {
    type: CONTACT_US_SUBMIT_FAIL,
    error: error,
  };
}
export const contactUsSubmit = (request: ContactUsRequest | ContactUsPrivateRequest) => async (dispatch: Dispatch) => {
  try {
    dispatch(contactUsSubmitStartAction());
    await miscService.contactUs(request);
    dispatch(contactUsSubmitSuccessAction(true));
    FirebaseService.fireEvent(FirebaseService.event_menu_user_count_contact_us_mail);
  } catch (error) {
    dispatch(contactUsSubmitFailAction(getErrorDtoFromApiError(error)));
  }
};

const contactUsSubmitResetAction = (): ContactUsSubmitResetAction => {
  return {
    type: CONTACT_US_SUBMIT_RESET,
  };
}
export const contactUsSubmitReset = () => (dispatch: Dispatch) => {
  dispatch(contactUsSubmitResetAction());
};
