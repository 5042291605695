import parse from "html-react-parser";
import headingTicket from "../../../assets/images/section-heading-priority-ticket.svg";
import ticket from "../../../assets/images/section-priority-ticket.png";
import FirebaseService from "../../../core/services/firebase.service";
import "./PriorityTicketBanner.scss";

interface IProps {}

const PriorityTicketBanner = (props: IProps) => {
  // const handleDetails = (): void => {
  //   history.push(`${router.CAMPAIGNS}/2`);
  // }

  return (
    <div id="priority-ticket-banner" className="section">
      <div className="heading">
        <img src={headingTicket} alt="ticket" />
        <div className="info">
          <span className="title">
            {parse(
              FirebaseService.getValue(FirebaseService.home_ticket_header)
            )}
          </span>
          <span className="text">
            {parse(FirebaseService.getValue(FirebaseService.home_ticket_desc))}
          </span>
        </div>
      </div>
      <div className="priority-ticket-body">
        <div className="side side-left">
          <span className="title">
            ÖNCELİKLİ BİLET
            <br />
            HAKKINI KAÇIRMA!
          </span>
          {/* <div
            className="button"
            onClick={handleDetails}
          >
            DETAYLAR
          </div> */}
        </div>
        <div className="side side-right">
          <img className="ticket" src={ticket} alt="ticket" />
        </div>
      </div>
    </div>
  );
};

export default PriorityTicketBanner;
