import parse from "html-react-parser";
import React from "react";
import FirebaseService from "../../core/services/firebase.service";
import "./AboutUsPage.scss";

interface IProps {
}

class AboutUsPage extends React.Component<IProps> {

  render() {
    return (
      <div id="about-us-page" className="page">
        <div className="page-content">
          <div className="section section-about col-12 mb-5 pb-3">
            <div className="heading">
							<span className="text">
								{parse(FirebaseService.getValue(FirebaseService.about_us_description))}
							</span>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default AboutUsPage;
