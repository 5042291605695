import {Button, message, Popover, Spin, Upload} from "antd";
import {RcFile, UploadChangeParam} from "antd/es/upload";
import parse from "html-react-parser";
import React, {ReactNode, useState} from "react";
import {connect} from "react-redux";
import {FacebookShareButton, TwitterShareButton} from "react-share";
import {bindActionCreators, Dispatch} from "redux";
import buttonAgainBlue from "../../../assets/images/personality-refresh-blue.svg";
import buttonAgain from "../../../assets/images/personality-refresh.svg";
import buttonShareYellow from "../../../assets/images/personality-share-yellow.svg";
import buttonShare from "../../../assets/images/personality-share.svg";
import camera from "../../../assets/images/test-camera.svg";
import download from "../../../assets/images/test-download.svg";
import facebook from "../../../assets/images/test-facebook.svg";
import placeholder from "../../../assets/images/test-image-placeholder.png";
import twitter from "../../../assets/images/test-twitter.svg";
import imageClose from "../../../assets/images/x.svg";
import {resetAvatar, setUserMe} from "../../../core/actions/app";
import axios from "../../../core/helpers/axios";
import {Constants} from "../../../core/helpers/constants";
import {Utilities} from "../../../core/helpers/utilities";
import {PersonalityDto} from "../../../core/models/dtos/personality.dto";
import {UserMeDto} from "../../../core/models/dtos/userMe.dto";
import {IStore} from "../../../core/reducers";
import FirebaseService from "../../../core/services/firebase.service";
import "./Cover.scss";

interface IProps {
  userMe?: UserMeDto,
  personality?: PersonalityDto;
  isCoverStart: boolean;
  isContinue: boolean;
  callbackStart: () => void;
  callbackAgain: () => void;
  setUserMe: (userMe: UserMeDto) => void;
  resetAvatar: () => void;
}

const Cover = (props: IProps) => {
  const [imageLoading, setImageLoading] = useState<boolean>(false);

  const beforeUpload = (file: RcFile): boolean => {
    const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
    if (!isJpgOrPng) {
      message.error('Dosya JPG/PNG olmalı!');
    }
    const isLt3M = file.size / 1024 / 1024 < 2;
    if (!isLt3M) {
      message.error('Dosya boyutu en fazla 2MB olmalı!');
    }
    return isJpgOrPng && isLt3M;
  }

  const uploadFile = async (options: any) => {
    const {onSuccess, file} = options;
    const formData = new FormData();
    const config = {
      headers: {
        "content-type": "multipart/form-data",
      },
    };
    formData.append("file", file);
    await axios.post(
      `${process.env.REACT_APP_BASE_URL}/personality/create-avatar`,
      formData,
      config
    )
      .then((res) => {
        FirebaseService.fireEvent(FirebaseService.event_quiz_count_upload_pic);
        onSuccess(res.data)
      })
      .catch(() => setImageLoading(false));
  };

  const handleChange = (info: UploadChangeParam) => {
    if (info.file.status === 'uploading') {
      setImageLoading(true);
      return;
    }
    setImageLoading(false);
    if (info.file.status === 'done') {
      const userMe = props.userMe;
      if (userMe) {
        userMe.avatarUrl = info.file.response;
        props.setUserMe(userMe);
      }
    }
  };

  const handleDeleteAvatar = (): void => {
    props.resetAvatar();
  }

  const handleButton = (): void => {
    if (props.isCoverStart) {
      FirebaseService.fireEvent(FirebaseService.event_quiz_count_start_button_clicked);
    }
    props.isCoverStart
      ? props.callbackStart()
      : props.callbackAgain();
  }

  const getShareUrl = (isDownload: boolean = false): string => {
    if (props.userMe?.avatarUrl) {
      return props.userMe?.avatarUrl;
    }
    if (isDownload) {
      return Constants.fenerLogo;
    }
    return Constants.shareUrl;
  }

  // Not working, cors error
  /* eslint-disable  @typescript-eslint/no-unused-vars */
  const handleDownload = async (event: React.MouseEvent<HTMLImageElement, MouseEvent>) => {
    event.preventDefault();
    const response = await fetch(getShareUrl(true), {mode: 'no-cors'})
      .catch(() => message.error('Dosya kaydedilemedi!'));
    if (response.status === 200) {
      const blob = await response.blob();
      const url = URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = url;
      link.download = "image";
      document.body.appendChild(link);
      link.click();
      link.remove();
      return {success: true};
    } else {
      message.error('Dosya kaydedilemedi!');
    }
  };

  const shareContent = (): ReactNode => {
    return (
      <div className="share-content">
        <FacebookShareButton
          className="share-icon"
          url={getShareUrl()}
          quote={Utilities.getSocialShareText(props.personality)}
          onClick={() => FirebaseService.fireEvent(FirebaseService.event_quiz_count_share_facebook_button_clicked)}
        >
          <img className="social" src={facebook} alt="facebook"/>
        </FacebookShareButton>
        <TwitterShareButton
          className="share-icon"
          url={getShareUrl()}
          title={Utilities.getSocialShareTextTwitter(props.personality)}
          onClick={() => FirebaseService.fireEvent(FirebaseService.event_quiz_count_share_twitter_button_clicked)}
        >
          <img className="social" src={twitter} alt="twitter"/>
        </TwitterShareButton>
        <a
          className="share-icon ml-2"
          href={getShareUrl(true)}
          target="_blank"
          rel="noreferrer"
        >
          <img className="download" src={download} alt="download"/>
        </a>
      </div>
    );
  }

  const renderAvatarUpload = (): ReactNode => {
    return (
      <React.Fragment>
        <Upload
          listType="picture"
          className="avatar-uploader"
          accept="image/png, image/jpg"
          showUploadList={false}
          customRequest={uploadFile}
          beforeUpload={beforeUpload}
          onChange={handleChange}
        >
          <div
            key={props.userMe?.avatarUrl ?? 'placeholder'}
            className="avatar"
            style={{
              background: `url(${props.userMe?.avatarUrl ?? placeholder})`,
              backgroundSize: 'cover',
              backgroundRepeat: 'no-repeat',
              backgroundPosition: 'top',
            }}
          />
          {imageLoading && <Spin className="avatar image-spinner" size="large"/>}
          <div className="upload-tip">
            <div className="content">
              <img className="icon" src={camera} alt="camera"/>
              <span className="text">Fotoğrafı Değiştir</span>
            </div>
          </div>
        </Upload>
        <img
          className="delete-avatar"
          src={imageClose}
          alt="close"
          onClick={handleDeleteAvatar}
        />
      </React.Fragment>
    );
  }

  const renderCoverStart = (): ReactNode => {
    return (
      <div className="cover start">
        <div
          className="cover-image"
          style={{
            background: `url(${FirebaseService.getValue(FirebaseService.test_info_image)})`,
            backgroundSize: 'cover',
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center',
          }}
        />
        <p className="title">
          {parse(FirebaseService.getValue(FirebaseService.test_info_title))}
        </p>
        <hr className="line"/>
        <p className="info">
          {parse(FirebaseService.getValue(FirebaseService.test_info_description))}
        </p>
      </div>
    );
  }

  const renderCoverFinish = (): ReactNode => {
    if (!props.personality) {
      return (
        <div className="cover finish">
          Kişilik Hatası
        </div>
      );
    }
    return (
      <React.Fragment>

        <div className="cover finish d-block d-xl-none">
          {renderAvatarUpload()}
          <p className="title">
            {props.personality.title}
          </p>
          <p className="info">
            {props.personality.description}
          </p>
          <p className="sub-info">
            {parse(FirebaseService.getValue(FirebaseService.test_info_image_description))}
          </p>
        </div>

        <div className="cover finish d-none d-xl-flex large-screen">
          <div className="upload-wrapper">
            {renderAvatarUpload()}
          </div>
          <div className="large-screen-content">
            <p className="title">
              {props.personality.title}
            </p>
            <div className="inner-box">
              <p className="info">
                {props.personality.description}
              </p>
              <p className="sub-info">
                {parse(FirebaseService.getValue(FirebaseService.test_info_image_description))}
              </p>
              <div className="button-wrapper">
                <Button
                  className="personality-button button-outline"
                  onClick={handleButton}
                >
                  <img className="button-image" src={buttonAgainBlue} alt="again"/>
                  TEKRAR ÇÖZ
                </Button>
                <Popover
                  className="share-popover"
                  placement="top"
                  content={shareContent}
                  trigger="click"
                >
                  <Button
                    className="personality-button button-solid"
                  >
                    <img className="button-image" src={buttonShareYellow} alt="share"/>
                    PAYLAŞ
                  </Button>
                </Popover>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }

  return (
    <div id="test-cover">
      {props.isCoverStart ? renderCoverStart() : renderCoverFinish()}
      {
        props.isCoverStart
          ?
          <Button
            type="primary"
            className="cover-button"
            onClick={handleButton}
          >
            {props.isContinue
              ? FirebaseService.getValue(FirebaseService.test_info_button_continue)
              : FirebaseService.getValue(FirebaseService.test_info_button)
            }
          </Button>
          :
          <div className="button-wrapper d-flex d-xl-none">
            <Button
              className="personality-button button-outline"
              onClick={handleButton}
            >
              <img className="button-image" src={buttonAgain} alt="again"/>
              TEKRAR ÇÖZ
            </Button>
            <Popover
              className="share-popover"
              placement="top"
              content={shareContent}
              trigger="click"
            >
              <Button
                className="personality-button button-solid"
              >
                <img className="button-image" src={buttonShare} alt="share"/>
                PAYLAŞ
              </Button>
            </Popover>
          </div>
      }
    </div>
  );
}

const mapDispatchToProps = (dispatch: Dispatch) => {
  return bindActionCreators(
    {
      setUserMe,
      resetAvatar,
    },
    dispatch
  );
};

const mapStateToProps = (store: IStore) => {
  return {
    userMe: store.app.userMe.data,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Cover);
