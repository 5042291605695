import parse from "html-react-parser";
import React, {ReactNode} from "react";
import {connect} from "react-redux";
import {bindActionCreators, Dispatch} from "redux";
import paymentSms from "../../../assets/images/mobile-payment.svg";
import AuthFooter from "../../../components/Auth/AuthFooter/AuthFooter";
import AuthHeaderBottom from "../../../components/Auth/AuthHeaderBottom/AuthHeaderBottom";
import AuthHeaderMobile from "../../../components/Auth/AuthHeaderMobile/AuthHeaderMobile";
import BodyLoading from "../../../components/Auth/BodyLoading/BodyLoading";
import FbCard from "../../../components/Auth/FbCard/FbCard";
import PageTitle from "../../../components/Auth/PageTitle/PageTitle";
import Privileges from "../../../components/Auth/Privileges/Privileges";
import SubscriptionFail from "../../../components/Auth/SubscriptionFail/SubscriptionFail";
import {logout} from "../../../core/actions/app";
import {
  mobilePaymentChargingSubmit,
  mobilePaymentSetCreditCardRedirect,
  mobilePaymentSmsConfirmationCheck
} from "../../../core/actions/mobilePayment";
import {Constants} from "../../../core/helpers/constants";
import {history} from "../../../core/helpers/history";
import {router} from "../../../core/helpers/router";
import {getStorage, storageToken} from "../../../core/helpers/storage";
import {ErrorDto} from "../../../core/models/dtos/error.dto";
import {MobilePaymentDto} from "../../../core/models/dtos/mobilePayment.dto";
import {UserMeDto} from "../../../core/models/dtos/userMe.dto";
import {IStore} from "../../../core/reducers";
import FirebaseService from "../../../core/services/firebase.service";
import "./MobilePaymentPage.scss";

interface IProps {
  storeUserMe?: UserMeDto;
  storeLoginMsisdnSubmitData: boolean;
  loadingCharging: boolean;
  dataCharging?: MobilePaymentDto;
  errorCharging?: ErrorDto;
  loadingSms: boolean;
  dataSms: boolean;
  errorSms?: ErrorDto;
  mobilePaymentChargingSubmit: () => void;
  mobilePaymentSmsConfirmationCheck: (smsCid: string) => void;
  mobilePaymentSetCreditCardRedirect: (isCreditCardRedirect: boolean) => void;
  logout: () => void;
}

interface IState {
  intervalId?: NodeJS.Timeout;
  clockInSecond: number;
}

class MobilePaymentPage extends React.Component<IProps> {
  state: IState = {
    intervalId: undefined,
    clockInSecond: 0,
  }

  private readonly smsConfirmationCheckIntervalInSecond = 30;
  private readonly smsConfirmationCheckTimeoutInSecond = 300;

  constructor(props: IProps) {
    super(props);
    if (!getStorage(storageToken)) {
      history.push(router.LOGIN_MSISDN);
    }
  }

  componentDidMount() {
    this.props.mobilePaymentChargingSubmit();
  }

  componentDidUpdate(prevProps: Readonly<IProps>, prevState: Readonly<{}>, snapshot?: any) {
    if (prevProps.loadingCharging && !this.props.loadingCharging) {
      if (this.props.dataCharging) {
        this.setIntervalSmsConfirmationCheck(this.props.dataCharging.smsCid);
      }
    }

    if (prevProps.loadingSms && !this.props.loadingSms) {
      if (this.props.errorSms !== undefined) {
        this.clearIntervalSmsConfirmationCheck();
      }
    }
  }

  private getSmsErrorText(): string {
    if (this.props.errorSms?.message === Constants.errorCodeMobilePaymentClosed) {
      return FirebaseService.getValue(FirebaseService.mobile_payment_closed_text);
    }
    return 'Bir sorun oluştu. Lütfen tekrar dene veya Kredi Kartı ile devam et';
  }

  private isTimeOut(): boolean {
    return this.state.clockInSecond > this.smsConfirmationCheckTimeoutInSecond;
  }

  private isSubscriptionFail(): boolean {
    return (
      !!this.props.errorCharging ||
      (!!this.props.dataCharging && (!!this.props.errorSms || this.isTimeOut()))
    );
  }

  private clockTick(): boolean {
    if (this.isTimeOut()) {
      this.clearIntervalSmsConfirmationCheck();
      return false;
    }
    this.setState((prevState: IState) =>
      ({clockInSecond: prevState.clockInSecond + this.smsConfirmationCheckIntervalInSecond})
    );
    return true;
  }

  private setIntervalSmsConfirmationCheck(smsCid: string): void {
    const intervalId: NodeJS.Timeout = setInterval(() => {
      if (this.clockTick()) {
        this.props.mobilePaymentSmsConfirmationCheck(smsCid);
      }
    }, this.smsConfirmationCheckIntervalInSecond * 1000);
    this.setState({intervalId: intervalId, clockInSecond: 0});
  }

  private clearIntervalSmsConfirmationCheck(): void {
    if (this.state.intervalId) {
      clearInterval(this.state.intervalId);
    }
  }

  private retryMobileCharging(): void {
    FirebaseService.fireEvent(FirebaseService.event_error_count_view_check_again_button_clicked);
    this.clearIntervalSmsConfirmationCheck();
    this.props.mobilePaymentChargingSubmit();
  }

  private handleRedirectCreditCard(): void {
    this.props.mobilePaymentSetCreditCardRedirect(true);
    history.push(router.PAY_BY_ME);
  }

  private handleLogout(): void {
    if (this.props.storeLoginMsisdnSubmitData) {
      FirebaseService.fireEvent(FirebaseService.event_error_count_view_return_login_button_clicked);
    } else {
      FirebaseService.fireEvent(FirebaseService.event_payment_count_return_login_button_clicked);
    }
    this.props.logout();
    history.push(router.LOGIN_MSISDN);
  }

  private renderSms(): ReactNode {
    return (
      <div className="sms-wrapper">
        <div className="d-none d-lg-block">
          <PageTitle title="ABONE OL"/>
        </div>
        <div id="welcome-text">
          <span className="text">
            {parse(FirebaseService.getValue(FirebaseService.payment_page_text))}
          </span>
        </div>
        <img className="img-sms" src={paymentSms} alt="sms"/>
        <p className="info">
          {parse(FirebaseService.getValue(FirebaseService.payment_page_note))}
        </p>
      </div>
    );
  }

  private renderContent(): ReactNode {
    if (this.props.loadingCharging) {
      return <BodyLoading/>;
    } else if (this.isSubscriptionFail()) {
      return (
        <SubscriptionFail
          isChangePaymentMethodAvailable={true}
          errorText={this.getSmsErrorText()}
          textChangePaymentMethod="KREDİ KARTI İLE DEVAM ET"
          callbackRetry={() => this.retryMobileCharging()}
          callbackChangePaymentMethod={() => this.handleRedirectCreditCard()}
          callbackLogout={() => this.handleLogout()}
        />
      );
    } else if (this.props.dataCharging) {
      return (
        <React.Fragment>
          <div className="d-flex d-lg-none flex-column">
            <FbCard/>
            {this.renderSms()}
          </div>
          <div className="d-none d-lg-flex row">
            <div className="side side-left">
              {this.renderSms()}
            </div>
            <div className="side side-right">
              <FbCard isBG={true}/>
            </div>
          </div>
        </React.Fragment>
      );
    }
  }

  render() {
    return (
      <div id="mobile-payment-page">
        <div className="container">
          <AuthHeaderBottom/>
          <AuthHeaderMobile
            isBackArrow={!this.isSubscriptionFail()}
            pageTitle="ABONE OL"
          />
          <div className="page-content">
            {this.renderContent()}
          </div>
        </div>
        <Privileges/>
        <AuthFooter/>
      </div>
    );
  }

  componentWillUnmount() {
    this.clearIntervalSmsConfirmationCheck();
  }
}

const mapDispatchToProps = (dispatch: Dispatch) => {
  return bindActionCreators(
    {
      mobilePaymentChargingSubmit,
      mobilePaymentSmsConfirmationCheck,
      mobilePaymentSetCreditCardRedirect,
      logout,
    },
    dispatch
  );
};
const mapStateToProps = (store: IStore) => {
  const state = store.mobilePayment;
  return {
    storeUserMe: store.app.userMe.data,
    storeLoginMsisdnSubmitData: store.loginMsisdn.submit.data,
    loadingCharging: state.charging.loading,
    dataCharging: state.charging.data,
    errorCharging: state.charging.error,
    loadingSms: state.sms.loading,
    dataSms: state.sms.data,
    errorSms: state.sms.error,
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(MobilePaymentPage);
