export const router = {
  HOME: "/",
  GIFT: "/hediye",
  PROFILE: "/profil",
  LANDING: "/",
  LOGIN_MSISDN: "/giris",
  LOGIN_OTP: "/login-verification",
  LOGIN_SUBSCRIPTION: "/login-subscription",
  SIGNUP_INFO: "/signup",
  SIGNUP_MSISDN: "/signup-verification",
  SUBSCRIPTION: "/subscription",
  MOBILE_PAYMENT: "/mobil-odeme",
  PAY_BY_ME: "/payment",
  PAY_BY_ME_SUCCESS: "/payment-redirect", // Backend related
  PAY_BY_ME_FAILED: "/payment-failed", // Backend related
  ABOUT_US: "/hakkimizda",
  MENU: "/menu",
  NOTIFICATIONS: "/bildirimler",
  FAQ: "/sss",
  CONTACT_US: "/bize-ulas",
  CAMPAIGNS: "/ayricaliklar",
  INVITE_FRIENDS: "/arkadaslarini-davet-et",
  EMAIL_CONFIRM: "/confirm-email",
  TEST: "/taraftar-tipi-testi",
  PHOTO_GALLERY: "/gallery",
  WHEEL: "/wheel",
};
