import Adjust from "@adjustcom/adjust-web-sdk";
import firebase from "firebase/app";
import fire from "../../firebase-config";
import { Constants } from "../helpers/constants";
import {
  getStorage,
  removeStorage,
  setStorage,
  storageFcmToken,
  storageToken,
} from "../helpers/storage";
import { Utilities } from "../helpers/utilities";
import { NotificationDto } from "../models/dtos/notification.dto";
import { PhotoGalleryItemDto } from "../models/dtos/photoGalleryItem.dto";
import UserService from "./user.service";

export default class FirebaseService {
  private static analytics = fire.analytics();
  private static userService: UserService = new UserService();

  public static async fetchAndActivateRemoteConfig(): Promise<void> {
    await fire
      .remoteConfig()
      .fetchAndActivate()
      .then(() => {})
      .catch((error) => {});
  }

  public static async getFcmToken(isLoggedId: boolean): Promise<void> {
    try {
      const messaging = fire.messaging();
      const fcmToken = await messaging.getToken({
        vapidKey: process.env.REACT_APP_FIREBASE_VAPID_KEY,
      });
      if (fcmToken) {
        if (isLoggedId) {
          this.userService
            .addPushToken(fcmToken)
            .then(() => {
              removeStorage(storageFcmToken);
              this.setMessageListener(messaging);
            })
            .catch(() => {});
        } else {
          setStorage(storageFcmToken, fcmToken);
        }
      }
    } catch (e) {
      console.log(e);
    }
  }

  private static setMessageListener(
    messaging: firebase.messaging.Messaging
  ): void {
    messaging.onMessage((payload) => {
      // console.log('onMessage:', payload);
    });
  }

  public static fireEvent(key: string): void {
    const env: string = `${process.env.REACT_APP_ENV}`;
    if (env !== "production") {
      console.log(`>> EVENT: ${key}`);
    }
    this.analytics.logEvent(key);
    this.sendAdjustEventIfAvailable(key);
  }

  public static getValue(key: string): string {
    return fire.remoteConfig().getString(key);
  }

  public static getPhotos(): PhotoGalleryItemDto[] {
    const photos = JSON.parse(
      fire.remoteConfig().getString(this.photo_gallery)
    ) as PhotoGalleryItemDto[];
    return photos.sort((a, b) => (a.date > b.date ? -1 : 1));
  }

  public static getNotifications(): NotificationDto[] {
    const notifications = JSON.parse(
      fire.remoteConfig().getString(this.notifications)
    ) as NotificationDto[];
    const markedNotifications = this.sortNotificationsByDate(
      notifications.filter((n) => n.isMarked)
    );
    const otherNotifications = this.sortNotificationsByDate(
      notifications.filter((n) => !n.isMarked)
    );
    return [...markedNotifications, ...otherNotifications];
  }

  private static sortNotificationsByDate(
    notifications: NotificationDto[]
  ): NotificationDto[] {
    return notifications.sort((a, b) => (a.date > b.date ? -1 : 1));
  }

  public static sendMarketingEventIfAvailable(): void {
    const event = Utilities.getQueryParam(Constants.queryParamInitEvent);
    if (event) {
      const eventsString = FirebaseService.getValue(this.INIT_EVENT_VALUES);
      const events = eventsString.split(
        Constants.queryParamInitEventValueSeparator
      );
      if (events.includes(event)) {
        FirebaseService.fireEvent(event);
      }
    }
  }

  private static sendAdjustEventIfAvailable(fbEventKey: string): void {
    switch (fbEventKey) {
      case FirebaseService.event_user_count_landing_view:
        this.adjustEvent("vr9yvt");
        break;
      case FirebaseService.event_user_count_signup_clicked:
        this.adjustEvent("b61r5j");
        break;
      case FirebaseService.event_user_count_login_clicked:
        this.adjustEvent("2u9ksf");
        break;
      case FirebaseService.event_user_count_view_MSISDN_checked:
        this.adjustEvent("5fc2v8");
        break;
      case FirebaseService.event_user_count_MSISDN_checked_login_clicked:
        this.adjustEvent("a837s4");
        break;
      case FirebaseService.event_user_count_MSISDN_checked_contact_us_clicked:
        this.adjustEvent("nnenfa");
        break;
      case FirebaseService.event_user_count_MSISDN_checked_already_member:
        this.adjustEvent("hif84d");
        break;
      case FirebaseService.event_payment_user_count_view:
        this.adjustEvent("usmg33");
        break;
      case FirebaseService.event_payment_count_return_login_button_clicked:
        this.adjustEvent("wamfut");
        break;
      case FirebaseService.event_payment_user_count_view_odeme_payment_successful:
        this.adjustEvent("uytxdu");
        break;
      case FirebaseService.event_login_user_count_view_otp:
        this.adjustEvent("obw5za");
        break;
      case FirebaseService.event_login_count_otp_checked_button_clicked:
        this.adjustEvent("5vmji5");
        break;
      case FirebaseService.event_login_count_otp_send_again_button_clicked:
        this.adjustEvent("dou5mq");
        break;
      case FirebaseService.event_login_user_count_view_otp_type_wrong:
        this.adjustEvent("l4ta0d");
        break;
      case FirebaseService.event_login_user_count_view_pending_status:
        this.adjustEvent("bogqdx");
        break;
      case FirebaseService.event_error_count_view_check_again_button_clicked:
        this.adjustEvent("uigw3s");
        break;
      case FirebaseService.event_error_count_view_return_login_button_clicked:
        this.adjustEvent("hq8yw9");
        break;
      case FirebaseService.event_quiz_count_start_button_clicked:
        this.adjustEvent("3i95d5");
        break;
      case FirebaseService.event_quiz_count_view_result:
        this.adjustEvent("5euu1x");
        break;
      case FirebaseService.event_quiz_count_question_clicked:
        this.adjustEvent("wj8u91");
        break;
      case FirebaseService.event_quiz_count_view_result_tip1:
        this.adjustEvent("ubcvvs");
        break;
      case FirebaseService.event_quiz_count_view_result_tip2:
        this.adjustEvent("w2e2qu");
        break;
      case FirebaseService.event_quiz_count_view_result_tip3:
        this.adjustEvent("elpkdg");
        break;
      case FirebaseService.event_quiz_count_view_result_tip4:
        this.adjustEvent("76lx5t");
        break;
      case FirebaseService.event_quiz_count_view_result_tip5:
        this.adjustEvent("smzpdz");
        break;
      case FirebaseService.event_quiz_count_upload_pic:
        this.adjustEvent("xrtgmh");
        break;
      case FirebaseService.event_quiz_count_share_facebook_button_clicked:
        this.adjustEvent("6goozo");
        break;
      case FirebaseService.event_quiz_count_share_twitter_button_clicked:
        this.adjustEvent("4ykj35");
        break;
      case FirebaseService.event_ref_count_start_button_clicked:
        this.adjustEvent("zaz955");
        break;
      case FirebaseService.event_ref_count_question_clicked:
        this.adjustEvent("8wdq45");
        break;
      case FirebaseService.event_ref_count_past_question_button_clicked:
        this.adjustEvent("ylpsq8");
        break;
      case FirebaseService.event_ref_count_past_button_clicked:
        this.adjustEvent("oym0fd");
        break;
    }
  }

  public static adjustEvent(eventId: string): void {
    let callbackParams = [];
    const token = getStorage(storageToken);
    if (token) {
      const userId = Utilities.getUserId(token);
      callbackParams.push({ key: "user_id", value: userId.toString() });
    }
    Adjust.trackEvent({
      eventToken: eventId,
      callbackParams,
    });
  }

  public static INIT_EVENT_VALUES = "INIT_EVENT_VALUES";
  public static about_us_description = "about_us_description";
  public static abroad_login_info_description = "abroad_login_info_description";
  public static abroad_login_info_subtext = "abroad_login_info_subtext";
  public static abroad_login_info_title = "abroad_login_info_title";
  public static answered_question_new_question_counter_description =
    "answered_question_new_question_counter_description";
  public static api_error_text_tckn_not_unique =
    "api_error_text_tckn_not_unique";
  public static api_error_title_tckn_not_unique =
    "api_error_title_tckn_not_unique";
  public static campaign_info_required_button = "campaign_info_required_button";
  public static campaign_info_required_text = "campaign_info_required_text";
  public static consent_popup_title = "consent_popup_title";
  public static consent_popup_desc = "consent_popup_desc";
  public static consent_popup_button1 = "consent_popup_button1";
  public static consent_popup_button2 = "consent_popup_button2";
  public static contact_us_subject = "contact_us_subject";
  public static faq_answers = "faq_answers";
  public static faq_page_description = "faq_page_description";
  public static faq_page_header = "faq_page_header";
  public static faq_questions = "faq_questions";
  public static ft_home_subscription_header = "ft_home_subscription_header";
  public static home_campaign_description = "home_campaign_description";
  public static home_campaign_headar = "home_campaign_headar";
  public static home_mail_description = "home_mail_description";
  public static home_mail_header = "home_mail_header";
  public static home_point_description = "home_point_description";
  public static home_point_header = "home_point_header";
  public static home_poll_description = "home_poll_description";
  public static home_poll_header = "home_poll_header";
  public static home_referandum_description = "home_referandum_description";
  public static home_referandum_header = "home_referandum_header";
  public static home_subscription_description = "home_subscription_description";
  public static home_subscription_header = "home_subscription_header";
  public static home_ticket_header = "home_ticket_header";
  public static home_ticket_desc = "home_ticket_desc";
  public static home_wheel_banner_active = "home_wheel_banner_active";
  public static home_wheel_banner_active_text = "home_wheel_banner_active_text";
  public static home_wheel_banner_passive = "home_wheel_banner_passive";
  public static home_wheel_banner_passive_text =
    "home_wheel_banner_passive_text";
  public static home_wheel_header = "home_wheel_header";
  public static home_wheel_description = "home_wheel_description";
  public static invite_friends_description = "invite_friends_description";
  public static invite_friends_header = "invite_friends_header";
  public static landing_page_wheel_popup_button =
    "landing_page_wheel_popup_button";
  public static landing_page_wheel_popup_text = "landing_page_wheel_popup_text";
  public static landing_page_wheel_popup_title =
    "landing_page_wheel_popup_title";
  public static landing_popup_button = "landing_popup_button";
  public static landing_popup_desc = "landing_popup_desc";
  public static landing_popup_title = "landing_popup_title";
  public static landing_description = "landing_description";
  public static landing_login_button = "landing_login_button";
  public static landing_note_text = "landing_note_text";
  public static landing_signup_button = "landing_signup_button";
  public static landing_sub_description = "landing_sub_description";
  public static landing_sub_title = "landing_sub_title";
  public static landing_title = "landing_title";
  public static login_button_text = "login_button_text";
  public static login_info_description = "login_info_description";
  public static login_info_subtext = "login_info_subtext";
  public static login_info_title = "login_info_title";
  public static mobile_payment_closed_text = "mobile_payment_closed_text";
  public static monthly_question_button = "monthly_question_button";
  public static monthly_question_header = "monthly_question_header";
  public static more_question = "more_question";
  public static name_surname_bdate_required = "name_surname_bdate_required";
  public static notification_empty = "notification_empty";
  public static notifications = "notifications";
  public static option_other = "option_other";
  public static option_other_ph = "option_other_ph";
  public static past_question_button_desktop = "past_question_button_desktop";
  public static past_question_button_mobile = "past_question_button_mobile";
  public static past_question_empty_desc = "past_question_empty_desc";
  public static past_question_empty_title = "past_question_empty_title";
  public static past_question_header = "past_question_header";
  public static payment_error_message = "payment_error_message";
  public static payment_page_note = "payment_page_note";
  public static payment_page_text = "payment_page_text";
  public static payment_page_title = "payment_page_title";
  public static photo_gallery = "photo_gallery";
  public static profile_consent_desc = "profile_consent_desc";
  public static profile_consent_button = "profile_consent_button";
  public static profile_contact_us_redirect = "profile_contact_us_redirect";
  public static profile_info_does_not_match = "profile_info_does_not_match";
  public static profile_info_error_button = "profile_info_error_button";
  public static profile_info_error_message = "profile_info_error_message";
  public static profile_tckn_error = "profile_tckn_error";
  public static profile_tckn_info = "profile_tckn_info";
  public static profile_update_success_message =
    "profile_update_success_message";
  public static referandum_button = "referandum_button";
  public static referandum_description = "referandum_description";
  public static referandum_header = "referandum_header";
  public static referandum_all_answered = "referandum_all_answered";
  public static referandum_not_yet_answered = "referandum_not_yet_answered";
  public static referandum_at_least_one_answered =
    "referandum_at_least_one_answered";
  public static reward_instructions = "reward_instructions";
  public static signup_description = "signup_description";
  public static test_info_button = "test_info_button";
  public static test_info_button_continue = "test_info_button_continue";
  public static test_info_button_again = "test_info_button_again";
  public static test_info_description = "test_info_description";
  public static test_info_image = "test_info_image";
  public static test_info_image_description = "test_info_image_description";
  public static test_info_title = "test_info_title";
  public static vpos_payment_succes_button = "vpos_payment_succes_button";
  public static vpos_payment_succes_description =
    "vpos_payment_succes_description";
  public static vpos_payment_succes_header = "vpos_payment_succes_header";
  public static wheel_button = "wheel_button";
  public static wheel_text = "wheel_text";
  public static wheel_title = "wheel_title";
  public static wheel_how_to_use_button = "wheel_how_to_use_button";

  public static wheel_how_to_use_title = "wheel_how_to_use_title";
  public static wheel_how_to_use_back_button = "wheel_how_to_use_back_button";

  public static landing_banner_text = "landing_banner_text";
  public static landing_banner_button = "landing_banner_button";

  public static event_landing_wheel_click = "landing_wheel_click";

  public static event_user_count_landing_view = "user_count_landing_view";
  public static event_user_count_signup_clicked = "user_count_signup_clicked";
  public static event_user_count_login_clicked = "user_count_login_clicked";

  public static event_user_count_view_MSISDN_checked =
    "user_count_view_MSISDN_checked";
  public static event_user_count_MSISDN_checked_login_clicked =
    "user_count_MSISDN_checked_login_clicked";
  public static event_user_count_MSISDN_checked_contact_us_clicked =
    "user_count_MSISDN_checked_contact_us_clicked";
  public static event_user_count_MSISDN_checked_already_member =
    "user_count_MSISDN_checked_already_member";

  public static event_payment_user_count_view = "payment_user_count_view";
  public static event_payment_count_return_login_button_clicked =
    "payment_count_return_login_button_clicked";
  public static event_payment_user_count_view_odeme_payment_successful =
    "payment_user_count_view_odeme_payment_successful";

  public static event_login_user_count_view_otp = "login_user_count_view_otp";
  public static event_login_count_otp_checked_button_clicked =
    "login_count_otp_checked_button_clicked";
  public static event_login_count_otp_send_again_button_clicked =
    "login_count_otp_send_again_button_clicked";
  public static event_login_user_count_view_otp_type_wrong =
    "login_user_count_view_otp_type_wrong";
  public static event_login_user_count_view_pending_status =
    "login_user_count_view_pending_status";
  public static event_error_count_view_check_again_button_clicked =
    "error_count_view_check_again_button_clicked";
  public static event_error_count_view_return_login_button_clicked =
    "error_count_view_return_login_button_clicked";

  public static event_menu_count_hamburger_button_clicked =
    "menu_count_hamburger_button_clicked";
  public static event_menu_count_notifications_button_clicked =
    "menu_count_notifications_button_clicked";
  public static event_menu_count_about_us_button_clicked =
    "menu_count_about_us_button_clicked";
  public static event_menu_count_faq_button_clicked =
    "menu_count_faq_button_clicked";
  public static event_menu_count_contact_us_button_clicked =
    "menu_count_contact_us_button_clicked";
  public static event_menu_user_count_contact_us_mail =
    "menu_user_count_contact_us_mail";
  public static event_menu_count_log_out_button_clicked =
    "menu_count_log_out_button_clicked";

  public static event_menu_count_nav_landing_button_clicked =
    "menu_count_nav_landing_button_clicked";
  public static event_menu_count_nav_profile_button_clicked =
    "menu_count_nav_profile_button_clicked";
  public static event_menu_count_nav_gift_button_clicked =
    "menu_count_nav_gift_button_clicked";
  public static event_menu_count_nav_invite_friends_button_clicked =
    "menu_count_nav_invite_friends_button_clicked";
  public static event_menu_user_count_invite_friends =
    "menu_user_count_invite_friends";
  public static event_menu_user_count_profile_change =
    "menu_user_count_profile_change";

  public static event_user_count_gift_win_button_clicked =
    "user_count_gift_win_button_clicked";
  public static event_user_count_fenerium_offer_button_clicked =
    "user_count_fenerium_offer_button_clicked";
  public static event_user_count_ticket_offer_button_clicked =
    "user_count_ticket_offer_button_clicked";
  public static event_user_count_more_offer_button_clicked =
    "user_count_more_offer_button_clicked";

  public static event_offer_user_count_fenerium_offer_button_clicked =
    "offer_user_count_fenerium_offer_button_clicked";
  public static event_offer_user_count_ticket_offer_button_clicked =
    "offer_user_count_ticket_offer_button_clicked";
  public static event_offer_user_count_ticket_offer_profile_button_clicked =
    "offer_user_count_ticket_offer_profile_button_clicked";

  public static event_user_count_view_main_page = "user_count_view_main_page";
  public static event_user_count_view_profile = "user_count_view_profile";
  public static event_user_count_view_gift = "user_count_view_gift";
  public static event_user_count_view_invite_us = "user_count_view_invite_us";
  public static event_user_count_view_notifications =
    "user_count_view_notifications";
  public static event_user_count_view_faq = "user_count_view_faq";
  public static event_user_count_view_contact_us = "user_count_view_contact_us";
  public static event_user_count_view_offer = "user_count_view_offer";

  public static event_quiz_count_start_button_clicked =
    "event_quiz_count_start_button_clicked";
  public static event_quiz_count_view_result = "event_quiz_count_view_result";
  public static event_quiz_count_question_clicked =
    "event_quiz_count_question_clicked";
  public static event_quiz_count_view_result_tip1 =
    "event_quiz_count_view_result_tip1";
  public static event_quiz_count_view_result_tip2 =
    "event_quiz_count_view_result_tip2";
  public static event_quiz_count_view_result_tip3 =
    "event_quiz_count_view_result_tip3";
  public static event_quiz_count_view_result_tip4 =
    "event_quiz_count_view_result_tip4";
  public static event_quiz_count_view_result_tip5 =
    "event_quiz_count_view_result_tip5";
  public static event_quiz_count_upload_pic = "event_quiz_count_upload_pic";
  public static event_quiz_count_share_facebook_button_clicked =
    "event_quiz_count_share_facebook_button_clicked";
  public static event_quiz_count_share_twitter_button_clicked =
    "event_quiz_count_share_twitter_button_clicked";

  public static event_ref_count_start_button_clicked =
    "event_ref_count_start_button_clicked";
  public static event_ref_count_question_clicked =
    "event_ref_count_question_clicked";
  public static event_ref_count_past_question_button_clicked =
    "event_ref_count_past_question_button_clicked";
  public static event_ref_count_past_button_clicked =
    "event_ref_count_past_button_clicked";

  public static event_taraftar_card_no_copy = "taraftar_card_no_copy";

  public static event_user_count_landing_banner_click =
    "user_count_landing_banner_click";
}
