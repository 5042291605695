import {Dispatch} from "redux";
import {PollModalInitialTab} from "../../components/Modals/PollModal/PollModal";
import {
  FETCH_ACTIVE_POLL_FAIL,
  FETCH_ACTIVE_POLL_RESET,
  FETCH_ACTIVE_POLL_START,
  FETCH_ACTIVE_POLL_SUCCESS,
  FETCH_HISTORY_FAIL,
  FETCH_HISTORY_RESET,
  FETCH_HISTORY_START,
  FETCH_HISTORY_SUCCESS,
  POST_POLL_ANSWER_FAIL,
  POST_POLL_ANSWER_RESET,
  POST_POLL_ANSWER_START,
  POST_POLL_ANSWER_SUCCESS,
  SET_POLL_MODAL_INITIAL_TAB,
  SET_POLL_MODAL_QUESTION
} from "../constants/poll";
import {Utilities} from "../helpers/utilities";
import {ErrorDto, getErrorDtoFromApiError} from "../models/dtos/error.dto";
import {PollDto} from "../models/dtos/poll.dto";
import {PollAnswerRequest} from "../models/requests/pollAnswer.request";
import PollService from "../services/poll.service";
import {
  FetchActivePollFailAction,
  FetchActivePollResetAction,
  FetchActivePollStartAction,
  FetchActivePollSuccessAction,
  FetchHistoryFailAction,
  FetchHistoryResetAction,
  FetchHistoryStartAction,
  FetchHistorySuccessAction,
  PostPollAnswerFailAction,
  PostPollAnswerResetAction,
  PostPollAnswerStartAction,
  PostPollAnswerSuccessAction,
  SetPollModalInitialTabAction,
  SetPollModalQuestionAction
} from "../types/poll";

const pollService: PollService = new PollService();

const setPollModalInitialTabAction = (payload?: PollModalInitialTab): SetPollModalInitialTabAction => {
  return {
    type: SET_POLL_MODAL_INITIAL_TAB,
    payload: payload,
  }
}
export const setPollModalInitialTab = (initialTab?: PollModalInitialTab) => (
  dispatch: Dispatch
) => {
  dispatch(setPollModalInitialTabAction(initialTab));
}
/**
 * ------------------------------------------------------------------------------------
 */
const setPollModalQuestionAction = (payload?: PollDto): SetPollModalQuestionAction => {
  return {
    type: SET_POLL_MODAL_QUESTION,
    payload: payload,
  }
}
export const setPollModalQuestion = (poll?: PollDto) => (
  dispatch: Dispatch
) => {
  dispatch(setPollModalQuestionAction(poll));
}
/**
 * ------------------------------------------------------------------------------------
 */
const fetchActivePollStartAction = (): FetchActivePollStartAction => {
  return {
    type: FETCH_ACTIVE_POLL_START,
  };
}
const fetchActivePollSuccessAction = (payload: PollDto[]): FetchActivePollSuccessAction => {
  return {
    type: FETCH_ACTIVE_POLL_SUCCESS,
    payload: payload,
  };
}
const fetchActivePollFailAction = (error: ErrorDto): FetchActivePollFailAction => {
  return {
    type: FETCH_ACTIVE_POLL_FAIL,
    error: error,
  };
}
const fetchActivePollResetAction = (): FetchActivePollResetAction => {
  return {
    type: FETCH_ACTIVE_POLL_RESET,
  };
}
export const pollActiveFetch = () => async (dispatch: Dispatch) => {
  try {
    dispatch(fetchActivePollStartAction());
    const pollDtos = await pollService.getActivePoll();
    dispatch(fetchActivePollSuccessAction(Utilities.getSortedPolls(pollDtos)));
  } catch (error) {
    dispatch(fetchActivePollFailAction(getErrorDtoFromApiError(error)));
  }
};
export const pollActiveReset = () => async (dispatch: Dispatch) => {
  dispatch(fetchActivePollResetAction());
};
/**
 * ------------------------------------------------------------------------------------
 */
const postPollAnswerStartAction = (): PostPollAnswerStartAction => {
  return {
    type: POST_POLL_ANSWER_START,
  };
}
const postPollAnswerSuccessAction = (payload: PollDto): PostPollAnswerSuccessAction => {
  return {
    type: POST_POLL_ANSWER_SUCCESS,
    payload: payload,
  };
}
const postPollAnswerFailAction = (error: ErrorDto): PostPollAnswerFailAction => {
  return {
    type: POST_POLL_ANSWER_FAIL,
    error: error,
  };
}
const postPollAnswerResetAction = (): PostPollAnswerResetAction => {
  return {
    type: POST_POLL_ANSWER_RESET,
  };
}
export const pollAnswerPost = (quizId: number, request: PollAnswerRequest, currentPolls: PollDto[]) => async (dispatch: Dispatch) => {
  try {
    dispatch(postPollAnswerStartAction());
    const pollDto = await pollService.postAnswer(quizId, request);
    const updatedPolls = currentPolls.filter(p => p.id !== quizId);
    updatedPolls.push(pollDto);
    dispatch(fetchActivePollSuccessAction(Utilities.getSortedPolls(updatedPolls)));
    dispatch(setPollModalQuestionAction(pollDto));
    dispatch(postPollAnswerSuccessAction(pollDto));
  } catch (error) {
    dispatch(postPollAnswerFailAction(getErrorDtoFromApiError(error)));
  }
};
export const pollAnswerReset = () => async (dispatch: Dispatch) => {
  dispatch(postPollAnswerResetAction());
};
/**
 * ------------------------------------------------------------------------------------
 */
const fetchHistoryStartAction = (): FetchHistoryStartAction => {
  return {
    type: FETCH_HISTORY_START,
  };
}
const fetchHistorySuccessAction = (payload: PollDto[]): FetchHistorySuccessAction => {
  return {
    type: FETCH_HISTORY_SUCCESS,
    payload: payload,
  };
}
const fetchHistoryFailAction = (error: ErrorDto): FetchHistoryFailAction => {
  return {
    type: FETCH_HISTORY_FAIL,
    error: error,
  };
}
const fetchHistoryResetAction = (): FetchHistoryResetAction => {
  return {
    type: FETCH_HISTORY_RESET,
  };
}
export const pollHistoryFetch = () => async (dispatch: Dispatch) => {
  try {
    dispatch(fetchHistoryStartAction());
    const pollDtos = await pollService.getHistory();
    dispatch(fetchHistorySuccessAction(pollDtos));
  } catch (error) {
    dispatch(fetchHistoryFailAction(getErrorDtoFromApiError(error)));
  }
};
export const pollHistoryReset = () => async (dispatch: Dispatch) => {
  dispatch(fetchHistoryResetAction());
};
