import {
  MOBILE_PAYMENT_CHARGING_REQUEST_FAIL,
  MOBILE_PAYMENT_CHARGING_REQUEST_START,
  MOBILE_PAYMENT_CHARGING_REQUEST_SUCCESS,
  MOBILE_PAYMENT_CREDIT_CARD_REDIRECT,
  MOBILE_PAYMENT_SMS_CONFIRMATION_CHECK_FAIL,
  MOBILE_PAYMENT_SMS_CONFIRMATION_CHECK_START,
  MOBILE_PAYMENT_SMS_CONFIRMATION_CHECK_SUCCESS
} from "../constants/mobilePayment";
import {MobilePaymentActions, MobilePaymentState} from "../types/mobilePayment";

const initialState: MobilePaymentState = {
  charging: {
    loading: false,
    data: undefined,
    error: undefined,
  },
  sms: {
    loading: false,
    data: false,
    error: undefined,
  },
  creditCardRedirect: false,
}

const reducer = (
  state = initialState,
  action: MobilePaymentActions,
): MobilePaymentState => {
  switch (action.type) {
    case MOBILE_PAYMENT_CHARGING_REQUEST_START:
      return {
        ...state,
        charging: {
          ...initialState.charging,
          loading: true,
        },
        sms: {
          ...initialState.sms,
        }
      }
    case MOBILE_PAYMENT_CHARGING_REQUEST_SUCCESS:
      return {
        ...state,
        charging: {
          ...state.charging,
          loading: false,
          data: action.payload,
        }
      }
    case MOBILE_PAYMENT_CHARGING_REQUEST_FAIL:
      return {
        ...state,
        charging: {
          ...state.charging,
          loading: false,
          error: action.error,
        }
      }
    case MOBILE_PAYMENT_SMS_CONFIRMATION_CHECK_START:
      return {
        ...state,
        sms: {
          ...initialState.sms,
          loading: true,
        }
      }
    case MOBILE_PAYMENT_SMS_CONFIRMATION_CHECK_SUCCESS:
      return {
        ...state,
        sms: {
          ...state.sms,
          loading: false,
          data: action.payload,
        }
      }
    case MOBILE_PAYMENT_SMS_CONFIRMATION_CHECK_FAIL:
      return {
        ...state,
        sms: {
          ...state.sms,
          loading: false,
          error: action.error,
        }
      }
    case MOBILE_PAYMENT_CREDIT_CARD_REDIRECT:
      return {
        ...state,
        creditCardRedirect: action.payload,
      }
    default:
      return state;
  }
}

export default reducer;
