import {Dispatch} from "redux";
import {
  LOGIN_SUBSCRIPTION_STATUS_CHECK_FAIL,
  LOGIN_SUBSCRIPTION_STATUS_CHECK_RESET,
  LOGIN_SUBSCRIPTION_STATUS_CHECK_START,
  LOGIN_SUBSCRIPTION_STATUS_CHECK_SUCCESS
} from "../constants/loginSubscription";
import {history} from "../helpers/history";
import {router} from "../helpers/router";
import {Utilities} from "../helpers/utilities";
import {ErrorDto, getErrorDtoFromApiError} from "../models/dtos/error.dto";
import {SubscriptionState} from "../models/enums/subscriptionState";
import AuthService from "../services/auth.service";
import FirebaseService from "../services/firebase.service";
import UserService from "../services/user.service";
import {
  LoginSubscriptionStatusCheckFailAction,
  LoginSubscriptionStatusCheckResetAction,
  LoginSubscriptionStatusCheckStartAction,
  LoginSubscriptionStatusCheckSuccessAction
} from "../types/loginSubscription";
import {setAppVisibilityAction, setUserMeAction} from "./app";

const authService: AuthService = new AuthService();
const userService: UserService = new UserService();

const loginSubscriptionStatusCheckStartAction = (): LoginSubscriptionStatusCheckStartAction => {
  return {
    type: LOGIN_SUBSCRIPTION_STATUS_CHECK_START,
  };
}
const loginSubscriptionStatusCheckSuccessAction = (payload: SubscriptionState): LoginSubscriptionStatusCheckSuccessAction => {
  return {
    type: LOGIN_SUBSCRIPTION_STATUS_CHECK_SUCCESS,
    payload: payload,
  };
}
const loginSubscriptionStatusCheckFailAction = (error: ErrorDto): LoginSubscriptionStatusCheckFailAction => {
  return {
    type: LOGIN_SUBSCRIPTION_STATUS_CHECK_FAIL,
    error: error,
  };
}
export const loginSubscriptionStatusCheck = (isCreditCardRedirect: boolean) => async (dispatch: Dispatch) => {
  try {
    dispatch(loginSubscriptionStatusCheckStartAction());
    const userMeDto = await userService.getDetails();
    if (userMeDto) {
      dispatch(setUserMeAction(userMeDto));
      const subscriptionState = userMeDto.subscriptionState;
      if (subscriptionState === SubscriptionState.ACTIVE) {
        await authService.refresh();
        dispatch(setAppVisibilityAction(true));
      } else {
        if (subscriptionState === SubscriptionState.PENDING) {
          FirebaseService.fireEvent(FirebaseService.event_login_user_count_view_pending_status);
        }
        const msisdn: string | null = Utilities.getMsisdnFromToken();
        msisdn ? Utilities.navigateToPayment(msisdn, isCreditCardRedirect) : history.push(router.HOME);
      }
      dispatch(loginSubscriptionStatusCheckSuccessAction(subscriptionState));
    }
  } catch (error) {
    dispatch(loginSubscriptionStatusCheckFailAction(getErrorDtoFromApiError(error)));
  }
};

const loginSubscriptionStatusCheckResetAction = (): LoginSubscriptionStatusCheckResetAction => {
  return {
    type: LOGIN_SUBSCRIPTION_STATUS_CHECK_RESET,
  };
}
export const loginSubscriptionStatusCheckReset = () => (dispatch: Dispatch) => {
  dispatch(loginSubscriptionStatusCheckResetAction());
};
