import parse from "html-react-parser";
import React, {ReactNode} from 'react';
import ButtonPrimary from "../../Buttons/ButtonPrimary/ButtonPrimary";
import FbCard from "../FbCard/FbCard";
import "./SubscriptionFail.scss";

interface IProps {
  isChangePaymentMethodAvailable: boolean;
  errorText: string;
  textChangePaymentMethod: string;
  callbackRetry: () => void;
  callbackChangePaymentMethod: () => void;
  callbackLogout: () => void;
}

const SubscriptionFail = (props: IProps) => {

  const renderContent = (): ReactNode => {
    return (
      <div className="content">
        <p className="title">Aboneliğin başlatılamadı.</p>
        <p className="text">{parse(props.errorText)}</p>
        <ButtonPrimary
          class="button-retry"
          text="TEKRAR DENE"
          arrowSuffix={true}
          callback={props.callbackRetry}
        />
        {
          props.isChangePaymentMethodAvailable &&
          <ButtonPrimary
            class="button-change-method"
            text={props.textChangePaymentMethod}
            callback={props.callbackChangePaymentMethod}
          />
        }
        <label
          className="button-logout"
          onClick={props.callbackLogout}
        >
          Vazgeç
        </label>
      </div>
    );
  }

  return (
    <div
      id="subscription-fail"
      className="pb-5"
    >
      <div className="d-flex d-lg-none flex-column">
        <FbCard isGray={true}/>
        {renderContent()}
      </div>
      <div className="d-none d-lg-flex row">
        <div className="side side-left">
          {renderContent()}
        </div>
        <div className="side side-right">
          <FbCard isBG={true} isGray={true}/>
        </div>
      </div>
    </div>
  );
}

export default SubscriptionFail;
