import { ReactNode } from "react";
import socialFacebook from "../../../assets/images/social-facebook.svg";
import socialInstagram from "../../../assets/images/social-instagram.svg";
import socialTiktok from "../../../assets/images/social-tiktok.svg";
import socialTwitter from "../../../assets/images/social-twitter.svg";
import socialYoutube from "../../../assets/images/social-youtube.svg";
import { Constants } from "../../../core/helpers/constants";
import "./AuthFooter.scss";

const AuthHeader = () => {
  const renderLink = (href: string, src: any): ReactNode => {
    return (
      <a href={href} target="_blank" rel="noreferrer">
        <img src={src} alt="social" />
      </a>
    );
  };

  return (
    <div id="auth-footer">
      <div className="d-flex flex-column">
        <div className="icon-wrapper">
          {renderLink(Constants.socialFbLink, socialFacebook)}
          {renderLink(Constants.socialXLink, socialTwitter)}
          {renderLink(Constants.socialInstagramLink, socialInstagram)}
          {renderLink(Constants.socialYtLink, socialYoutube)}
          {renderLink(Constants.socialTtLink, socialTiktok)}
        </div>
        <div className="branding">
          <span className="year">1907 - 2024</span>
          <span className="brand">FENERBAHÇE SK</span>
        </div>
      </div>
    </div>
  );
};

export default AuthHeader;
