import React from "react";
import AppFooter from "../../../components/AppFooter/AppFooter";
import AuthHeaderBottom from "../../../components/Auth/AuthHeaderBottom/AuthHeaderBottom";
import FaqPage from "../../FaqPage/FaqPage";
import "./FaqAnonPage.scss";

class FaqAnonPage extends React.Component {

  render() {
    return (
      <div id="faq-anon-page">
        <AuthHeaderBottom/>
        <FaqPage/>
        <AppFooter/>
      </div>
    );
  }
}

export default FaqAnonPage;
