import {
  CONTACT_US_SUBMIT_FAIL,
  CONTACT_US_SUBMIT_RESET,
  CONTACT_US_SUBMIT_START,
  CONTACT_US_SUBMIT_SUCCESS,
} from "../constants/contactUs";
import {ContactUsActions, ContactUsState} from "../types/contactUs";

const initialState: ContactUsState = {
  submit: {
    loading: false,
    data: false,
    error: undefined,
  }
}

const reducer = (
  state = initialState,
  action: ContactUsActions,
): ContactUsState => {
  switch (action.type) {
    case CONTACT_US_SUBMIT_START:
      return {
        ...state,
        submit: {
          ...initialState.submit,
          loading: true,
        }
      }
    case CONTACT_US_SUBMIT_SUCCESS:
      return {
        ...state,
        submit: {
          ...state.submit,
          loading: false,
          data: action.payload,
        }
      }
    case CONTACT_US_SUBMIT_FAIL:
      return {
        ...state,
        submit: {
          ...state.submit,
          loading: false,
          error: action.error,
        }
      }
    case CONTACT_US_SUBMIT_RESET:
      return {
        ...state,
        submit: {
          ...initialState.submit,
        }
      }
    default:
      return state;
  }
}

export default reducer;
