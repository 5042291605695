import {Dispatch} from "redux";
import {
  PROFILE_CANCEL_SUBSCRIPTION_FAIL,
  PROFILE_CANCEL_SUBSCRIPTION_RESET,
  PROFILE_CANCEL_SUBSCRIPTION_START,
  PROFILE_CANCEL_SUBSCRIPTION_SUCCESS,
  PROFILE_SEND_EMAIL_CONFIRM_FAIL,
  PROFILE_SEND_EMAIL_CONFIRM_RESET,
  PROFILE_SEND_EMAIL_CONFIRM_START,
  PROFILE_SEND_EMAIL_CONFIRM_SUCCESS,
  PROFILE_USER_ME_FETCH_FAIL,
  PROFILE_USER_ME_FETCH_START,
  PROFILE_USER_ME_FETCH_SUCCESS,
  PROFILE_USER_ME_RESET,
  PROFILE_USER_ME_SUBMIT_FAIL,
  PROFILE_USER_ME_SUBMIT_RESET,
  PROFILE_USER_ME_SUBMIT_START,
  PROFILE_USER_ME_SUBMIT_SUCCESS
} from "../constants/profile";
import {ErrorDto, ErrorGeneric, getErrorDtoFromApiError} from "../models/dtos/error.dto";
import {UserMeDto} from "../models/dtos/userMe.dto";
import {UserMeUpdateRequest} from "../models/requests/userMeUpdate.request";
import PaymentService from "../services/payment.service";
import UserService from "../services/user.service";
import {
  ProfileCancelSubscriptionFailAction,
  ProfileCancelSubscriptionResetAction,
  ProfileCancelSubscriptionStartAction,
  ProfileCancelSubscriptionSuccessAction,
  ProfileSendEmailConfirmFailAction,
  ProfileSendEmailConfirmResetAction,
  ProfileSendEmailConfirmStartAction,
  ProfileSendEmailConfirmSuccessAction,
  ProfileUserMeFetchFailAction,
  ProfileUserMeFetchStartAction,
  ProfileUserMeFetchSuccessAction,
  ProfileUserMeResetAction,
  ProfileUserMeSubmitFailAction,
  ProfileUserMeSubmitResetAction,
  ProfileUserMeSubmitStartAction,
  ProfileUserMeSubmitSuccessAction
} from "../types/profile";
import {setUserMeAction} from "./app";

const userService: UserService = new UserService();
const paymentService: PaymentService = new PaymentService();

/**
 * USER_ME_FETCH
 */
const profileUserMeFetchStartAction = (): ProfileUserMeFetchStartAction => {
  return {
    type: PROFILE_USER_ME_FETCH_START,
  }
}
const profileUserMeFetchSuccessAction = (payload: UserMeDto): ProfileUserMeFetchSuccessAction => {
  return {
    type: PROFILE_USER_ME_FETCH_SUCCESS,
    payload: payload,
  }
}
const profileUserMeFetchFailAction = (error: ErrorDto): ProfileUserMeFetchFailAction => {
  return {
    type: PROFILE_USER_ME_FETCH_FAIL,
    error: error,
  }
}
export const profileFetchUserMe = () => async (
  dispatch: Dispatch
) => {
  try {
    dispatch(profileUserMeFetchStartAction());
    const userMeDto = await userService.getDetails();
    if (userMeDto) {
      dispatch(setUserMeAction(userMeDto));
      dispatch(profileUserMeFetchSuccessAction(userMeDto));
    } else {
      dispatch(profileUserMeFetchFailAction(ErrorGeneric));
    }
  } catch (error) {
    dispatch(profileUserMeFetchFailAction(getErrorDtoFromApiError(error)));
  }
}

const profileUserMeResetAction = (): ProfileUserMeResetAction => {
  return {
    type: PROFILE_USER_ME_RESET,
  }
}
export const profileResetUserMe = () => (
  dispatch: Dispatch
) => {
  dispatch(profileUserMeResetAction());
}

/**
 * SEND_EMAIL_CONFIRM
 */
const profileSendEmailConfirmStartAction = (): ProfileSendEmailConfirmStartAction => {
  return {
    type: PROFILE_SEND_EMAIL_CONFIRM_START,
  };
}
const profileSendEmailConfirmSuccessAction = (payload: boolean): ProfileSendEmailConfirmSuccessAction => {
  return {
    type: PROFILE_SEND_EMAIL_CONFIRM_SUCCESS,
    payload: payload,
  };
}
const profileSendEmailConfirmFailAction = (error: ErrorDto): ProfileSendEmailConfirmFailAction => {
  return {
    type: PROFILE_SEND_EMAIL_CONFIRM_FAIL,
    error: error,
  };
}
export const profileSendEmailConfirm = () => async (
  dispatch: Dispatch
) => {
  try {
    dispatch(profileSendEmailConfirmStartAction());
    await userService.resendConfirmationEmail();
    dispatch(profileSendEmailConfirmSuccessAction(true));
  } catch (error) {
    dispatch(profileSendEmailConfirmFailAction(getErrorDtoFromApiError(error)));
  }
};

const profileSendEmailConfirmResetAction = (): ProfileSendEmailConfirmResetAction => {
  return {
    type: PROFILE_SEND_EMAIL_CONFIRM_RESET,
  };
}
export const profileSendEmailConfirmReset = () => (dispatch: Dispatch) => {
  dispatch(profileSendEmailConfirmResetAction());
};

/**
 * USER_ME_SUBMIT
 */
const profileUserMeSubmitStartAction = (): ProfileUserMeSubmitStartAction => {
  return {
    type: PROFILE_USER_ME_SUBMIT_START,
  };
}
const profileUserMeSubmitSuccessAction = (payload: boolean): ProfileUserMeSubmitSuccessAction => {
  return {
    type: PROFILE_USER_ME_SUBMIT_SUCCESS,
    payload: payload,
  };
}
const profileUserMeSubmitFailAction = (error: ErrorDto): ProfileUserMeSubmitFailAction => {
  return {
    type: PROFILE_USER_ME_SUBMIT_FAIL,
    error: error,
  };
}
export const profileSubmitUserMe = (request: UserMeUpdateRequest) => async (
  dispatch: Dispatch
) => {
  try {
    dispatch(profileUserMeSubmitStartAction());
    await userService.updateDetails(request);
    try {
      const userMeDto = await userService.getDetails();
      if (userMeDto) {
        dispatch(profileUserMeFetchSuccessAction(userMeDto));
        dispatch(setUserMeAction(userMeDto));
      }
    } catch (e) {
      // Not an epic fail, no need to handle. Just updates userMe for app.
    }
    dispatch(profileUserMeSubmitSuccessAction(true));
  } catch (error) {
    dispatch(profileUserMeSubmitFailAction(getErrorDtoFromApiError(error)));
  }
};

const profileUserMeSubmitResetAction = (): ProfileUserMeSubmitResetAction => {
  return {
    type: PROFILE_USER_ME_SUBMIT_RESET,
  };
}
export const profileSubmitUserMeReset = () => (dispatch: Dispatch) => {
  dispatch(profileUserMeSubmitResetAction());
};

/**
 * CANCEL_SUBSCRIPTION
 */
const profileCancelSubscriptionStartAction = (): ProfileCancelSubscriptionStartAction => {
  return {
    type: PROFILE_CANCEL_SUBSCRIPTION_START,
  };
}
const profileCancelSubscriptionSuccessAction = (payload: boolean): ProfileCancelSubscriptionSuccessAction => {
  return {
    type: PROFILE_CANCEL_SUBSCRIPTION_SUCCESS,
    payload: payload,
  };
}
const profileCancelSubscriptionFailAction = (error: ErrorDto): ProfileCancelSubscriptionFailAction => {
  return {
    type: PROFILE_CANCEL_SUBSCRIPTION_FAIL,
    error: error,
  };
}
export const profileCancelSubscription = () => async (
  dispatch: Dispatch
) => {
  try {
    dispatch(profileCancelSubscriptionStartAction());
    await paymentService.cancelSubscription();
    const userMeDto = await userService.getDetails();
    dispatch(setUserMeAction(userMeDto));
    dispatch(profileCancelSubscriptionSuccessAction(true));
  } catch (error) {
    dispatch(profileCancelSubscriptionFailAction(getErrorDtoFromApiError(error)));
  }
};

const profileCancelSubscriptionResetAction = (): ProfileCancelSubscriptionResetAction => {
  return {
    type: PROFILE_CANCEL_SUBSCRIPTION_RESET,
  };
}
export const profileCancelSubscriptionReset = () => (dispatch: Dispatch) => {
  dispatch(profileCancelSubscriptionResetAction());
};
