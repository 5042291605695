import {Progress} from "antd";
import React, {ReactNode} from "react";
import arrowLeftDisabled from "../../../assets/images/chevron-left-disabled.svg";
import arrowLeft from "../../../assets/images/chevron-left.svg";
import arrowRightDisabled from "../../../assets/images/chevron-right-disabled.svg";
import arrowRight from "../../../assets/images/chevron-right.svg";
import {Utilities} from "../../../core/helpers/utilities";
import {QuestionOption} from "../../../core/models/custom/questionOption";
import {OptionDto} from "../../../core/models/dtos/option.dto";
import {QuestionDto} from "../../../core/models/dtos/question.dto";
import "./Question.scss";

interface IProps {
  currentIndex: number;
  question: QuestionDto;
  selectedOptionId?: number;
  percent: number;
  submittedOptionId?: number;
  callbackSelectAnswer: (questionOption: QuestionOption) => void;
  callbackPrev?: () => void;
  callbackNext?: () => void;
}

const Question = (props: IProps) => {

  const handleSelectAnswer = (optionId: number): void => {
    props.callbackSelectAnswer({
      questionId: props.question.id,
      optionId: optionId
    });
  }

  const handlePrev = (): void => {
    if (props.callbackPrev) props.callbackPrev();
  }

  const handleNext = (): void => {
    if (props.callbackNext) props.callbackNext();
  }

  const getAnswerClass = (optionId: number): string => {
    let isSelected: boolean;
    if (props.submittedOptionId) {
      isSelected = props.submittedOptionId === optionId;
    } else {
      isSelected = props.selectedOptionId === optionId;
    }
    return `answer no-select ${isSelected ? 'selected' : ''}`;
  }

  const renderAnswer = (option: OptionDto, index: number): ReactNode => {
    return (
      <div
        key={`answer-${option.id}`}
        className={getAnswerClass(option.id)}
        onClick={() => handleSelectAnswer(option.id)}
      >
        <div className="choice">{Utilities.getLetterFromIndex(index)}</div>
        <label className="text">{option.text}</label>
      </div>
    );
  }

  return (
    <div id="test-question">
      <div className="question-wrapper">
        <label className={`no ${props.currentIndex + 1 > 9 ? 'big' : ''}`}>
          {props.currentIndex + 1}.
        </label>
        <label className="question no-select">{props.question.text}</label>
        <div className="answers">
          {props.question.options.map((option, index) => renderAnswer(option, index))}
        </div>
      </div>
      <div className="progress-wrapper">
        <div className="test-navigator">
          {props.callbackPrev
            ? <img src={arrowLeft} alt="left" onClick={handlePrev}/>
            : <img src={arrowLeftDisabled} alt="left"/>
          }
          {props.callbackNext
            ? <img src={arrowRight} alt="right" onClick={handleNext}/>
            : <img src={arrowRightDisabled} alt="right" onClick={handleNext}/>
          }
        </div>
        <div className="test-progress">
        <span className="info">
          {props.percent}% tamamlandı
        </span>
          <Progress
            percent={props.percent}
            showInfo={false}
            strokeColor={"#FFED00"}
          />
        </div>
      </div>
    </div>
  );
}

export default Question;
