import {SET_SIGNUP_INFO} from "../constants/signupInfo";
import {SignupInfoActions, SignupInfoState} from "../types/signupInfo";

const initialState: SignupInfoState = {
  data: undefined,
}

const reducer = (
  state = initialState,
  action: SignupInfoActions,
): SignupInfoState => {
  switch (action.type) {
    case SET_SIGNUP_INFO:
      return {
        ...state,
        data: action.payload,
      }
    default:
      return state;
  }
}

export default reducer;
