import React, {ReactNode} from "react";
import {connect} from "react-redux";
import {bindActionCreators, Dispatch} from "redux";
import success from "../../assets/images/contact-us-success.svg";
import {contactUsSubmit} from "../../core/actions/contactUs";
import {Utilities} from "../../core/helpers/utilities";
import {FormValuesContactUsPrivate} from "../../core/models/custom/formValuesContactUsPrivate";
import {ErrorDto} from "../../core/models/dtos/error.dto";
import {UserMeDto} from "../../core/models/dtos/userMe.dto";
import {ContactUsPrivateRequest} from "../../core/models/requests/contactUsPrivate.request";
import {IStore} from "../../core/reducers";
import FirebaseService from "../../core/services/firebase.service";
import ContactUsPrivateForm from "./ContactUsPrivateForm/ContactUsPrivateForm";
import "./ContactUsPrivatePage.scss";

interface IProps {
  userMe?: UserMeDto;
  loadingSubmit: boolean;
  dataSubmit: boolean;
  errorSubmit?: ErrorDto;
  contactUsSubmit: (request: ContactUsPrivateRequest) => void;
}

interface IState {
  page: Page
  isErrorField: boolean;
  isErrorGeneric: boolean;
}

export interface IFormInitialValues {
  fullName?: string,
  email?: string
}

enum Page {
  form,
  success,
}

class ContactUsPrivatePage extends React.Component<IProps> {
  state: IState = {
    page: Page.form,
    isErrorField: false,
    isErrorGeneric: false,
  }

  private readonly errorField = 'LÜTFEN TÜM ALANLARI DOLDURUNUZ.';
  private readonly errorGeneric = 'BEKLENMEYEN BİR HATA OLUŞTU.';

  componentDidMount() {
    FirebaseService.fireEvent(FirebaseService.event_user_count_view_contact_us);
  }

  componentDidUpdate(prevProps: Readonly<IProps>, prevState: Readonly<{}>, snapshot?: any) {
    if (prevProps.loadingSubmit && !this.props.loadingSubmit) {
      if (this.props.dataSubmit) {
        this.setState({page: Page.success});
      } else {
        this.setState({errorGeneric: true});
      }
    }
  }

  // todo handle error
  private getError(): string | undefined {
    if (this.state.isErrorField) {
      return this.errorField;
    } else if (this.state.isErrorGeneric) {
      return this.errorGeneric;
    }
    return undefined;
  }

  private getFormInitialValues(): IFormInitialValues {
    let fullName: string = '';
    if (this.props.userMe?.firstName) {
      fullName += this.props.userMe?.firstName;
    }
    if (this.props.userMe?.lastName) {
      fullName += ' ' + this.props.userMe?.lastName;
    }
    return {
      fullName: fullName,
      email: this.props.userMe?.email,
    }
  }

  private async handleSubmit(values: FormValuesContactUsPrivate): Promise<void> {
    this.setState({isErrorField: false, isErrorGeneric: false});
    const request: ContactUsPrivateRequest = {
      email: values.email,
      firstName: Utilities.getFirstNameFromFullName(values.fullName),
      lastName: Utilities.getLastNameFromFullName(values.fullName),
      subject: values.subject,
      message: values.message,
    };
    await this.props.contactUsSubmit(request);
  }

  private renderContent(): ReactNode {
    if (this.state.page === Page.form) {
      return (
        <ContactUsPrivateForm
          initialValues={this.getFormInitialValues()}
          isSubmitDisabled={this.props.loadingSubmit}
          callbackSubmit={values => this.handleSubmit(values)}
          callbackError={() => this.setState({isErrorField: true})}
        />
      );
    } else {
      return (
        <div className="success-wrapper">
          <img className="image" src={success} alt="success"/>
          <p className="text">
            Mesajın bize ulaştı, en yakın zamanda geri dönüş yapacağız.
          </p>
        </div>
      );
    }
  }

  render() {
    return (
      <div id="contact-us-private-page" className="page">
        <div className="page-content">
          {this.renderContent()}
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch: Dispatch) => {
  return bindActionCreators(
    {
      contactUsSubmit,
    },
    dispatch
  );
};
const mapStateToProps = (store: IStore) => {
  const state = store.contactUs;
  return {
    userMe: store.app.userMe.data,
    loadingSubmit: state.submit.loading,
    dataSubmit: state.submit.data,
    errorSubmit: state.submit.error,
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(ContactUsPrivatePage);
