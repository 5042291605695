import {CloseCircleOutlined} from "@ant-design/icons";
import {Button} from "antd";
import parse from "html-react-parser";
import React from "react";
import FirebaseService from "../../../core/services/firebase.service";
import ButtonPrimary from "../../Buttons/ButtonPrimary/ButtonPrimary";
import "./FailedSubscription.scss";

interface IProps {
  errorText?: string;
  callbackRetry: () => void;
  callbackRedirectCreditCard: () => void;
  callbackLogout: () => void;
}

const FailedSubscription = (props: IProps) => {

  return (
    <div id="failed-subscription">
      <CloseCircleOutlined className="img-fail-icon"/>
      <p className="info error">
        {parse(props.errorText ?? FirebaseService.getValue(FirebaseService.payment_error_message))}
      </p>
      <ButtonPrimary
        text="Tekrar Dene"
        callback={props.callbackRetry}
      />
      <p className="or">Ya da</p>
      <ButtonPrimary
        text="Kredi Kartı ile Ödeme Yap"
        class="redirect-credit-card"
        callback={props.callbackRedirectCreditCard}
      />
      <div className="d-flex justify-content-center">
        <Button
          className="link-button mt-5"
          type="link"
          onClick={props.callbackLogout}
        >
          Giriş sayfasına dön
        </Button>
      </div>
    </div>
  );
}

export default FailedSubscription;
