export interface ErrorDto {
  status: number;
  path: string;
  message: string;
}

export const ErrorGeneric: ErrorDto = {
  status: 500,
  path: '',
  message: 'Something went wrong'
}

export const ErrorLogin: ErrorDto = {
  status: 418,
  path: '',
  message: 'Go to signup'
}

export const getErrorDtoFromApiError = (error: any): ErrorDto => {
  if (!error.response || error.response.status === 500) {
    return ErrorGeneric;
  }
  return error.response.data;
}
