export const SET_LOGIN_MSISDN = 'SET_LOGIN_MSISDN';

export const LOGIN_MSISDN_SUBMIT_START = 'LOGIN_MSISDN_SUBMIT_START';
export const LOGIN_MSISDN_SUBMIT_SUCCESS = 'LOGIN_MSISDN_SUBMIT_SUCCESS';
export const LOGIN_MSISDN_SUBMIT_FAIL = 'LOGIN_MSISDN_SUBMIT_FAIL';
export const LOGIN_MSISDN_SUBMIT_RESET = 'LOGIN_MSISDN_SUBMIT_RESET';

export const LOGIN_CODE_SUBMIT_START = 'LOGIN_CODE_SUBMIT_START';
export const LOGIN_CODE_SUBMIT_SUCCESS = 'LOGIN_CODE_SUBMIT_SUCCESS';
export const LOGIN_CODE_SUBMIT_FAIL = 'LOGIN_CODE_SUBMIT_FAIL';
export const LOGIN_CODE_SUBMIT_RESET = 'LOGIN_CODE_SUBMIT_RESET';
