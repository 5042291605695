import {Form, Input, Select} from "antd";
import React from "react";
import ButtonPrimary from "../../../../components/Buttons/ButtonPrimary/ButtonPrimary";
import {history} from "../../../../core/helpers/history";
import {Utilities} from "../../../../core/helpers/utilities";
import {FormValuesContactUs} from "../../../../core/models/custom/formValuesContactUs";
import FirebaseService from "../../../../core/services/firebase.service";
import "./ContactUsForm.scss";

const {Option} = Select;
const {TextArea} = Input;

interface IProps {
  isSubmitDisabled: boolean;
  callbackSubmit: (values: FormValuesContactUs) => void;
  callbackError: () => void
}

const ContactUsForm = (props: IProps) => {

  const getSubjects = (): string[] => {
    return FirebaseService.getValue(FirebaseService.contact_us_subject).split("|");
  }

  const handleKeyPressName = (e: React.KeyboardEvent) => {
    if (!Utilities.isCharacterALetter(e.key)) {
      e.preventDefault();
      return false;
    }
  }

  return (
    <Form
      id="contact-us-form"
      onFinish={values => props.callbackSubmit(values)}
      onFinishFailed={props.callbackError}
    >
      <h1 className="title">BİZE ULAŞ</h1>

      <Form.Item
        name="msisdn"
        rules={[
          {required: true, message: ''},
        ]}
      >
        <Input
          className="form-input"
          type="tel"
          placeholder="Telefon numarası"
        />
      </Form.Item>

      <Form.Item
        name="firstName"
        rules={[
          {required: true, message: ''},
        ]}
      >
        <Input
          className="form-input"
          type="text"
          placeholder="İsim"
          onKeyPress={handleKeyPressName}
        />
      </Form.Item>

      <Form.Item
        name="lastName"
        rules={[
          {required: true, message: ''},
        ]}
      >
        <Input
          className="form-input"
          type="text"
          placeholder="Soyisim"
          onKeyPress={handleKeyPressName}
        />
      </Form.Item>

      <Form.Item
        name="email"
        rules={[
          {required: true, message: ''},
          {type: "email", message: ''},
        ]}
      >
        <Input
          className="form-input"
          type="text"
          placeholder="E-mail"
        />
      </Form.Item>

      <Form.Item
        name="subject"
        rules={[
          {required: true, message: ''},
        ]}
      >
        <Select
          placeholder="Mesajın konusu"
        >
          {getSubjects().map((subject, index) => {
            return (
              <Option
                key={`subject-${index}`}
                value={subject}
              >
                {subject}
              </Option>
            );
          })}
        </Select>
      </Form.Item>

      <Form.Item
        name="message"
        rules={[
          {required: true, message: ''},
        ]}
      >
        <TextArea
          className="text-area"
          rows={8}
          placeholder="Mesajını yaz"
        />
      </Form.Item>

      <div className="d-flex flex-column align-items-center">
        <ButtonPrimary
          class="button-yellow"
          text="Gönder"
          isSubmit={true}
          disabled={props.isSubmitDisabled}
        />

        <label
          className="cancel-link"
          onClick={() => history.goBack()}
        >
          İptal
        </label>
      </div>
    </Form>
  );
}

export default ContactUsForm;
