export const PROFILE_USER_ME_FETCH_START = 'PROFILE_USER_ME_FETCH_START';
export const PROFILE_USER_ME_FETCH_SUCCESS = 'PROFILE_USER_ME_FETCH_SUCCESS';
export const PROFILE_USER_ME_FETCH_FAIL = 'PROFILE_USER_ME_FETCH_FAIL';
export const PROFILE_USER_ME_RESET = 'PROFILE_USER_ME_RESET';

export const PROFILE_SEND_EMAIL_CONFIRM_START = 'PROFILE_SEND_EMAIL_CONFIRM_START';
export const PROFILE_SEND_EMAIL_CONFIRM_SUCCESS = 'PROFILE_SEND_EMAIL_CONFIRM_SUCCESS';
export const PROFILE_SEND_EMAIL_CONFIRM_FAIL = 'PROFILE_SEND_EMAIL_CONFIRM_FAIL';
export const PROFILE_SEND_EMAIL_CONFIRM_RESET = 'PROFILE_SEND_EMAIL_CONFIRM_RESET';

export const PROFILE_USER_ME_SUBMIT_START = 'PROFILE_USER_ME_SUBMIT_START';
export const PROFILE_USER_ME_SUBMIT_SUCCESS = 'PROFILE_USER_ME_SUBMIT_SUCCESS';
export const PROFILE_USER_ME_SUBMIT_FAIL = 'PROFILE_USER_ME_SUBMIT_FAIL';
export const PROFILE_USER_ME_SUBMIT_RESET = 'PROFILE_USER_ME_SUBMIT_RESET';

export const PROFILE_CANCEL_SUBSCRIPTION_START = 'PROFILE_CANCEL_SUBSCRIPTION_START';
export const PROFILE_CANCEL_SUBSCRIPTION_SUCCESS = 'PROFILE_CANCEL_SUBSCRIPTION_SUCCESS';
export const PROFILE_CANCEL_SUBSCRIPTION_FAIL = 'PROFILE_CANCEL_SUBSCRIPTION_FAIL';
export const PROFILE_CANCEL_SUBSCRIPTION_RESET = 'PROFILE_CANCEL_SUBSCRIPTION_RESET';
