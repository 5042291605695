import {Modal} from "antd";
import React from "react";
import ButtonPrimary from "../../../../../components/Buttons/ButtonPrimary/ButtonPrimary";
import "./ModalContract.scss";

interface IProps {
  visible: boolean;
  callbackOk: Function;
  callbackCancel: Function;
}

const ModalContract = (props: IProps) => {

  return (
    <Modal
      className="modal-contract"
      visible={props.visible}
      closable={true}
      footer={false}
      centered={false}
      onCancel={() => props.callbackCancel()}
    >
      <div className="modal-content">

        <div className="text">
          Lorem ipsum dolor sit amet, consectetur adipisicing elit. Architecto aut consectetur corporis cum, delectus
          dicta dolores eaque, exercitationem iure minima modi, numquam obcaecati officia porro rem tenetur unde
          voluptas. Amet dolor dolores, dolorum est exercitationem explicabo facilis iste libero minima modi mollitia
          nemo nihil nisi obcaecati officiis quasi quibusdam quisquam quo recusandae saepe sunt tenetur ut! Amet,
          debitis harum illo ipsam laboriosam maxime pariatur quod voluptates voluptatum. Amet asperiores dolorum, ipsa
          iusto maxime natus optio quae qui recusandae vitae. Deserunt fuga hic ipsa quas sed! Aspernatur consequatur
          dignissimos fuga in inventore, labore neque non odit praesentium quae reprehenderit rerum ullam veritatis vero
          voluptatem. A ad aperiam at debitis delectus dolorem ducimus esse ex exercitationem explicabo fuga fugiat illo
          ipsa, iste labore laborum maiores nostrum officiis optio porro qui sequi veritatis voluptatibus? Ab accusamus
          amet, atque commodi debitis doloribus earum eos fuga, inventore ipsa labore libero minima nesciunt nobis nulla
          placeat rerum saepe tempore? A aliquid asperiores, cum doloribus incidunt ipsam iste iusto maxime officiis
          quam! A aut consequuntur debitis dignissimos dolores exercitationem fuga laboriosam magni necessitatibus nemo
          nihil non perferendis perspiciatis praesentium, quas qui quibusdam quod recusandae sapiente sequi sit suscipit
          tempora tenetur vel vitae? Facere nihil nobis numquam saepe voluptatum! Id porro totam unde. Aliquam
          aspernatur blanditiis dolores eos itaque laborum minus nisi nobis non, obcaecati, odit possimus quam qui rem
          saepe soluta totam ullam. Ad adipisci molestias natus quis quod? Asperiores atque cum doloribus dolorum enim,
          est exercitationem impedit, laboriosam modi molestias non numquam odio quod sint, tempore vel voluptatem.
          Aperiam assumenda dolor, dolorum ea excepturi facere, fugiat laborum nisi nobis, nulla quasi quisquam
          repellendus repudiandae sunt tempore! Aliquid aperiam at cum nemo odio omnis reprehenderit rerum, saepe
          similique! Aliquid animi architecto at deleniti deserunt doloribus earum excepturi facere facilis illo magni
          nam natus praesentium quaerat quasi qui quisquam reprehenderit saepe sequi, sint tempore ullam unde voluptates
          voluptatibus voluptatum! Atque aut blanditiis commodi debitis ea earum eius eligendi eos eveniet ex, excepturi
          exercitationem id illo, impedit incidunt labore laborum magnam minus modi nihil nisi numquam pariatur
          provident quae quisquam quo repellat saepe sed, sunt suscipit temporibus totam ullam veniam! Accusamus aperiam
          asperiores aspernatur aut blanditiis commodi, consequuntur cupiditate deserunt doloribus eaque earum facilis
          illo ipsum laudantium, modi, molestiae mollitia odit omnis pariatur sequi similique tempora voluptates. Animi
          asperiores aspernatur corporis debitis, dicta doloribus, enim, eum hic illo molestias necessitatibus quam quo
          ratione repellendus repudiandae similique sit tempora tenetur?
        </div>
      </div>

      <div className="d-flex justify-content-center mt-3">
        <ButtonPrimary
          text="ONAYLIYORUM"
          callback={props.callbackOk}
        />
      </div>
    </Modal>
  );
}

export default ModalContract;
