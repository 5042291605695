import {Spin} from "antd";
import React from "react";
import {Constants} from "../../../../core/helpers/constants";
import {setStorage, storageVposResult} from "../../../../core/helpers/storage";
import "./PayByMeFailedPage.scss";

class PayByMeFailedPage extends React.Component {

  componentDidMount() {
    setStorage(storageVposResult, Constants.vposResultFailed);
  }

  render() {
    return (
      <div id="pay-by-me-failed-page">
        <Spin size="large"/>
      </div>
    );
  }
}

export default PayByMeFailedPage;
