import { Dispatch } from "redux";
import {
  LOGIN_OTP_RESEND_FAIL,
  LOGIN_OTP_RESEND_RESET,
  LOGIN_OTP_RESEND_START,
  LOGIN_OTP_RESEND_SUCCESS,
  LOGIN_OTP_SUBMIT_FAIL,
  LOGIN_OTP_SUBMIT_RESET,
  LOGIN_OTP_SUBMIT_START,
  LOGIN_OTP_SUBMIT_SUCCESS
} from "../constants/loginOtp";
import { removeStorage, setStorage, storageRecaptcha, storageToken } from "../helpers/storage";
import { ErrorDto, ErrorGeneric, getErrorDtoFromApiError } from "../models/dtos/error.dto";
import { TokenDto } from "../models/dtos/token.dto";
import { LoginRequest } from "../models/requests/login.request";
import { OtpRequest } from "../models/requests/otp.request";
import AuthService from "../services/auth.service";
import FirebaseService from "../services/firebase.service";
import GTMService from "../services/gtm.service";
import {
  LoginOtpResendFailAction,
  LoginOtpResendResetAction,
  LoginOtpResendStartAction,
  LoginOtpResendSuccessAction,
  LoginOtpSubmitFailAction,
  LoginOtpSubmitResetAction,
  LoginOtpSubmitStartAction,
  LoginOtpSubmitSuccessAction
} from "../types/loginOtp";

const authService: AuthService = new AuthService();

const loginOtpSubmitStartAction = (): LoginOtpSubmitStartAction => {
  return {
    type: LOGIN_OTP_SUBMIT_START,
  };
}
const loginOtpSubmitSuccessAction = (payload: TokenDto): LoginOtpSubmitSuccessAction => {
  return {
    type: LOGIN_OTP_SUBMIT_SUCCESS,
    payload: payload,
  };
}
const loginOtpSubmitFailAction = (error: ErrorDto): LoginOtpSubmitFailAction => {
  return {
    type: LOGIN_OTP_SUBMIT_FAIL,
    error: error,
  };
}
export const loginOtpSubmit = (request: LoginRequest) => async (
  dispatch: Dispatch
) => {
  try {
    dispatch(loginOtpSubmitStartAction());
    const tokenDto = await authService.login(request);
    if (tokenDto) {
      setStorage(storageToken, tokenDto.token);
      removeStorage(storageRecaptcha);
      dispatch(loginOtpSubmitSuccessAction(tokenDto));
      FirebaseService.getFcmToken(true);
      GTMService.eventLogin();
    } else {
      dispatch(loginOtpSubmitFailAction(ErrorGeneric));
    }
  } catch (error) {
    FirebaseService.fireEvent(FirebaseService.event_login_user_count_view_otp_type_wrong);
    dispatch(loginOtpSubmitFailAction(getErrorDtoFromApiError(error)));
  }
};

const loginOtpSubmitResetAction = (): LoginOtpSubmitResetAction => {
  return {
    type: LOGIN_OTP_SUBMIT_RESET,
  };
}
export const loginOtpSubmitReset = () => (dispatch: Dispatch) => {
  dispatch(loginOtpSubmitResetAction());
};

const loginOtpResendStartAction = (): LoginOtpResendStartAction => {
  return {
    type: LOGIN_OTP_RESEND_START,
  };
}
const loginOtpResendSuccessAction = (payload: boolean): LoginOtpResendSuccessAction => {
  return {
    type: LOGIN_OTP_RESEND_SUCCESS,
    payload: payload,
  };
}
const loginOtpResendFailAction = (error: ErrorDto): LoginOtpResendFailAction => {
  return {
    type: LOGIN_OTP_RESEND_FAIL,
    error: error,
  };
}
export const loginOtpResend = (request: OtpRequest) => async (
  dispatch: Dispatch
) => {
  try {
    dispatch(loginOtpResendStartAction());
    await authService.sendOtp(request);
    dispatch(loginOtpResendSuccessAction(true));
  } catch (error) {
    dispatch(loginOtpResendFailAction(getErrorDtoFromApiError(error)));
  }
};

const loginOtpResendResetAction = (): LoginOtpResendResetAction => {
  return {
    type: LOGIN_OTP_RESEND_RESET,
  };
}
export const loginOtpResendReset = () => (dispatch: Dispatch) => {
  dispatch(loginOtpResendResetAction());
};
