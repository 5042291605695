import { Form, Input } from "antd";
import React, { useState } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import ButtonPrimary from "../../../../../components/Buttons/ButtonPrimary/ButtonPrimary";
import { Constants } from "../../../../../core/helpers/constants";
import { FormValuesLoginOtp } from "../../../../../core/models/custom/formValuesLoginOtp";
import "./LoginOtpForm.scss";

interface IProps {
  isSubmitDisabled: boolean;
  callbackSubmit: (values: FormValuesLoginOtp) => void;
  callbackError: () => void;
  callbackResend: (captcha: string) => void;
}

const LoginOtpForm = (props: IProps) => {
  const optLength: number = 4;
  const [form] = Form.useForm();
  const [isResendClicked, setResendClicked] = useState<boolean>(false);
  const [isRecaptcha, setIsRecaptcha] = useState<boolean>(false);
  const [recaptchaToken, setRecaptchaToken] = useState("");
  const [isCaptchaError, setCaptchaError] = useState<boolean>(false);

  const handleOtpChange = (otp: string) => {
    if (otp && otp.length > optLength) {
      form.setFieldsValue({ otp: otp.substring(0, optLength) });
    }
  };

  const handleOtpKeyPress = (e: React.KeyboardEvent) => {
    if (e.key === "e" || isNaN(Number(e.key))) {
      e.preventDefault();
    }
  };

  const handleResend = () => {
    if (!isRecaptcha) {
      setIsRecaptcha(true);
      return;
    }
    if (!recaptchaToken) {
      setCaptchaError(true);
      return;
    }
    setResendClicked(true);
    setIsRecaptcha(false);
    setRecaptchaToken("");
    props.callbackResend(recaptchaToken);
  };

  const handleRecaptchaChange = (token: string | null) => {
    if (token) {
      setCaptchaError(false);
      setRecaptchaToken(token);
    }
  };

  function renderRecaptcha() {
    if (isRecaptcha) {
      return (
        <ReCAPTCHA
          className={`recaptcha mb-3 ${isCaptchaError ? "error" : ""}`}
          sitekey={Constants.recaptchaSiteKey}
          onChange={handleRecaptchaChange}
        />
      );
    }
  }

  return (
    <Form
      id="login-otp-form"
      form={form}
      onFinish={(values) => props.callbackSubmit(values)}
      onFinishFailed={props.callbackError}
    >
      <Form.Item
        name="otp"
        rules={[
          { required: true, message: "" },
          { min: optLength, message: "" },
          { max: optLength, message: "" },
        ]}
      >
        <Input
          className="form-input border-primary"
          autoFocus={true}
          autoComplete="off"
          type="tel"
          onChange={(e) => handleOtpChange(e.target.value)}
          onKeyPress={handleOtpKeyPress}
        />
      </Form.Item>

      <p className="info">
        {`SMS ile gelen ${optLength} haneli onay kodunu gir.`}
        <br />
        Giriş SMS’ini almadıysan bize ulaşabilirsin.
      </p>
      {renderRecaptcha()}
      <label
        className={`link-button ${isResendClicked ? "clicked" : ""}`}
        onClick={handleResend}
      >
        TEKRAR KOD GÖNDER
      </label>

      <div className="d-flex justify-content-center">
        <ButtonPrimary
          class="button-yellow"
          text="Devam Et"
          isSubmit={true}
          disabled={props.isSubmitDisabled}
        />
      </div>
    </Form>
  );
};

export default LoginOtpForm;
