import parse from "html-react-parser";
import React from "react";
import {connect} from "react-redux";
import {NavLink} from "react-router-dom";
import {bindActionCreators, Dispatch} from "redux";
import {router} from "../../core/helpers/router";
import {CampaignDto} from "../../core/models/dtos/campaign.dto";
import {UserMeDto} from "../../core/models/dtos/userMe.dto";
import {CampaignFirebaseName} from "../../core/models/enums/campaignFirebaseName";
import {CampaignState} from "../../core/models/enums/campaignState";
import {IStore} from "../../core/reducers";
import FirebaseService from "../../core/services/firebase.service";
import "./CampaignCard.scss";

interface IProps {
  pathname: string;
  campaign: CampaignDto;
  className?: string;
  userMe?: UserMeDto;
}

const CampaignCard = (props: IProps) => {

  const isButtonWhite = (): boolean => {
    const isRequired: boolean = props.campaign.tcknRequired;
    const isUserTckn: boolean = !!props.userMe?.tckn;
    const isUserFirstName: boolean = !!props.userMe?.firstName;
    const isUserLastName: boolean = !!props.userMe?.lastName;
    return isRequired && (!isUserTckn || !isUserFirstName || !isUserLastName);
  }

  const getNavLink = (): string => {
    const path = props.campaign.path;
    if (path) {
      return path.charAt(0) === '/' ? path : `/${path}`;
    }
    return `${router.CAMPAIGNS}/${props.campaign.id}`;
  }

  const handleButtonClick = (): void => {
    if (props.pathname === router.CAMPAIGNS) {
      if (props.campaign.firebaseName === CampaignFirebaseName.fenerium) {
        FirebaseService.fireEvent(FirebaseService.event_offer_user_count_fenerium_offer_button_clicked);
      } else if (props.campaign.firebaseName === CampaignFirebaseName.ticket) {
        FirebaseService.fireEvent(FirebaseService.event_offer_user_count_ticket_offer_button_clicked);
      }
    } else {
      if (props.campaign.firebaseName === CampaignFirebaseName.fenerium) {
        FirebaseService.fireEvent(FirebaseService.event_user_count_fenerium_offer_button_clicked);
      } else if (props.campaign.firebaseName === CampaignFirebaseName.ticket) {
        FirebaseService.fireEvent(FirebaseService.event_user_count_ticket_offer_button_clicked);
      }
    }
  }

  return (
    <div
      id="campaign-card"
      className={props.className}
    >
      <div className="body">
        <div
          className="content"
          style={{background: `url(${props.campaign.imageUrl}) no-repeat center`, backgroundSize: "contain"}}
        >
          <div
            className="card-header"
          >
            {parse(props.campaign.header)}
          </div>

          <span
            className="card-name"
          >
            {parse(props.campaign.name)}
          </span>

          {
            props.campaign.state === CampaignState.ONGOING &&
            <NavLink
              className={`card-button ${isButtonWhite() ? 'white' : 'green'}`}
              to={getNavLink()}
              onClick={handleButtonClick}
            >
              {props.campaign.buttonText}
            </NavLink>
          }
        </div>
      </div>
    </div>
  );
}

const mapDispatchToProps = (dispatch: Dispatch) => {
  return bindActionCreators(
    {},
    dispatch
  );
};

const mapStateToProps = (store: IStore) => {
  return {
    pathname: store.app.pathname,
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(CampaignCard);
